import { motion } from "framer-motion"
import { Feeling } from "src/components/EntryEditor/Sidebar/FeelingsModule/Feeling"
import { CloseIcon } from "src/components/Icons"
import { FeelingsModule } from "./FeelingsModule/FeelingsModule"

export enum SidebarModuleType {
    FEELINGS = "FEELINGS",
}

export type SidebarModule = {
    type: SidebarModuleType,
    data: ModuleData,
    gptFunction?: (input: string) => string
}

export type ModuleData = {
    
}

export type FeelingsModuleData = ModuleData & {
    feelings: Feeling[]
}

export const SidebarModule = (
    {
        module,
        onModuleDataChanged,
        onModuleDismissed,
        debouncedJournalText,
    } : {
        module: SidebarModule,
        onModuleDataChanged : (data: ModuleData) => void
        onModuleDismissed: () => void,
        debouncedJournalText: string
    }
) => {

    const renderModule = () => {
        switch (module.type) {
            case SidebarModuleType.FEELINGS:
                return <FeelingsModule module={module} onModuleDataChanged={onModuleDataChanged} debouncedJournalText={debouncedJournalText}/>
        }
    }

    return <div className={`overflow-hidden ${Object.keys(module.data).length > 0 ? "h-auto" : "h-0"}`}>
        {<motion.div 
            key={module.type}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: {duration: 1} }}
            exit={{ opacity: 0 }}>
            <div className={`group relative border-t border-l border-neutral-500 rounded-tl-lg p-2 sm:opacity-50 hover:opacity-100 focus-within:opacity-100 transition-all duration-500
                            `}>
                
                {renderModule()}
            </div>
        </motion.div>
        }
    </div>
}






import { useState } from "react";
import { RightArrow } from "src/components/Icons";
import { Suggestion, SuggestionList } from "src/components/Suggestions/Suggestion";

export const SuggestionListCarousel = ({
    suggestionList,
    onSelect
}: {
    suggestionList: SuggestionList,
    onSelect: (suggestion: Suggestion) => void
}) => {

    const [index, setIndex] = useState(0);
    const suggestion = suggestionList.suggestions[index];

    return <div className="w-full flex flex-row items-center space-x-4">
        <div className="w-full flex flex-col space-y-1"   >
            <div className="text-xs font-bold">
                {suggestionList.type}
            </div>
            <div onClick={() => { onSelect(suggestion) }}>
                {suggestion?.description}
            </div>
            <div>
                <DropdownItemPagination index={index} count={suggestionList.suggestions.length} setIndex={setIndex} />
            </div>
        </div>
        <div className="w-8 flex flex-col items-center h-full cursor-pointer fill-neutral-500 hover:fill-neutral-900"
            onClick={() => { setIndex(index < suggestionList.suggestions.length - 1 ? index + 1 : 0) }}>
            {suggestionList.suggestions.length > 1 && <div className="py-4">{RightArrow}</div>}
        </div>

    </div>
}

const DropdownItemPagination = (
    {
        index,
        count,
        setIndex,
    }: {
        index: number,
        count: number,
        setIndex: (index: number) => void,

    }) => {

    const getPagination = () => {
        let pagination = [];
        for (let i = 0; i < count; i++) {
            pagination.push(
                <div
                    className={`w-2 h-2 border border-neutral-500 rounded-full cursor-pointer hover:scale-125 ${i === index ? "bg-neutral-500" : ""}`}
                    onClick={() => { setIndex(i) }}
                    key={i} />
            )
        }
        return pagination;
    }

    return <div className="w-full flex flex-row space-x-4 items-center justify-center pt-2">
        {count > 1 && getPagination()}

    </div>
}

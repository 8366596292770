import { db } from "../firebase"
import { addDoc, collection, limit, orderBy, query, where, Timestamp } from "firebase/firestore"
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { Entry, entryConverter } from "../components/EntryEditor/Entry";
import { useEffect, useState } from "react";

export const useLatestEntry = (uid: string): {entry: Entry | undefined, loading: boolean} => {
    const entriesRef = collection(db, "users", uid, "entries").withConverter(entryConverter);
    
    const q = query(entriesRef, orderBy("date_created", "desc"), limit(1));
    const [collectionValue, loading, error, reload] = useCollectionOnce(q);

    let entry = undefined;
    if (collectionValue && collectionValue.docs.length > 0) {
        entry = collectionValue.docs[0].data() as Entry;
    }

    return {
        entry: entry,
        loading: loading
    }

}

// finds most recent entry from Today
const useLatestEntryFromToday = (uid: string): {entry: Entry | undefined, loading: boolean} => {
    const entriesRef = collection(db, "users", uid, "entries").withConverter(entryConverter);
    var today = new Date();
    today.setUTCHours(0,0,0,0);
    const todayTimestamp = Timestamp.fromDate(today);
    const q = query(entriesRef, where("date_created", ">=", todayTimestamp), orderBy("date_created", "desc"), limit(1));
    const [collectionValue, loading, error, reload] = useCollectionOnce(q);

    let entry = undefined;
    if (collectionValue && collectionValue.docs.length > 0) {
        entry = collectionValue.docs[0].data() as Entry;
    }

    return {
        entry: entry,
        loading: loading
    }
}

const useCurrentEntryOrCreateNew = (uid: string): Entry | undefined => {
    const entriesRef = collection(db, "users", uid, "entries").withConverter(entryConverter);
    
    //get the beginning of the current date
    var today = new Date();
    today.setUTCHours(0,0,0,0);
    const todayTimestamp = Timestamp.fromDate(today);
    const q = query(entriesRef, where("date_created", ">=", todayTimestamp), orderBy("date_created", "desc"), limit(1));
   
    const [collectionValue, loading, error, reload] = useCollectionOnce(q);
    const [entry, setEntry] = useState<Entry | undefined>(undefined);

    useEffect(() => {
        if (collectionValue) {
            if (collectionValue.docs.length > 0) {
                setEntry(collectionValue.docs[0].data() as Entry);
            }
            else {
                //create a new doc
                addDoc(collection(db, "users", uid, "entries"), {
                    text: "",
                    date_created: new Date(),
                    pmJSON: [],
                    feelings: []
                }).then(
                    (docRef) => {
                        //reload the collection
                        reload();
                    }
                )
                .catch(
                    (e) => {
                        console.error("Error adding document: ", e)
                    }
                )
            }
        }
    }, [collectionValue])

    return entry;

}

export {useLatestEntryFromToday}
import { useEffect, useState } from "react"
import { ModuleData, SidebarModule, SidebarModuleType } from "./SidebarModule"
import { AnimatePresence } from "framer-motion"
import { RemirrorJSON } from "remirror"
import { useLengthDebounce } from "src/lib/useLengthDebounce"
import { LeftArrow, RightArrow } from "src/components/Icons"

const Sidebar = (
    { 
        mobileExpanded,
        sidebarModules,
        onSidebarModulesChanged,
        journalPMJSON,
        toggleExpanded
    } : {
        mobileExpanded: boolean,
        sidebarModules: SidebarModule[],
        onSidebarModulesChanged: (newSidebarModules: SidebarModule[]) => void,
        journalPMJSON: RemirrorJSON,
        toggleExpanded: () => void
    }
) => {
    
    const debouncedJournalText = useLengthDebounce(journalPMJSON, 100);

    const updateModuleData = (moduleIndex: number, newData: ModuleData) => {
        const newModule = {
            type: sidebarModules[moduleIndex].type,
            data: newData
        }

        const newModules = [...sidebarModules.splice(0, moduleIndex), newModule, ...sidebarModules.splice(moduleIndex + 1)];
        onSidebarModulesChanged(newModules);
    }

    const removeModule = (moduleIndex: number) => {
        onSidebarModulesChanged([...sidebarModules.splice(0, moduleIndex), ...sidebarModules.splice(moduleIndex + 1)]);
    }

    useEffect(() => {
    }, [debouncedJournalText])

    useEffect(() => {
        if (!sidebarModules) {
            sidebarModules = [];
        }
        if (sidebarModules.length == 0) {
            const basicFeelingsModule:SidebarModule = {
                type: SidebarModuleType.FEELINGS,
                data: {}
            };
    
            onSidebarModulesChanged([basicFeelingsModule]);
        }
    }, [])

    return <div className="relative flex flex-col">
        <div className={`absolute sm:hidden pt-1 z-50 ${mobileExpanded ? "right-[0%]" : "right-[100%]"}`}
            onClick={toggleExpanded}>
                <div className={`p-2 rounded-full text-xs font-bold ${mobileExpanded ? "" : "border border-tifla-green  pr-6"}`}>
                    {mobileExpanded ? RightArrow : LeftArrow}
                </div>
        </div>
        <AnimatePresence>
        {
            sidebarModules && sidebarModules.map(
                (module, index) => <SidebarModule 
                    key={index}
                    module={module}
                    onModuleDataChanged={(data:ModuleData) => {updateModuleData(index, data)}} 
                    onModuleDismissed={() => {removeModule(index)}}
                    debouncedJournalText={debouncedJournalText}/>)}
        </AnimatePresence>
    </div>
}



export {Sidebar}
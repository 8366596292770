export const BasicButton = (
    { 
        onClick, 
        children, 
        disabled
    }: {
        onClick: () => void,
        children: React.ReactNode,
        disabled?: boolean
    }) => {
    
    return <div 
            onClick={onClick}
            className="w-fit text-center rounded-full border border-tifla-green py-1 px-3 cursor-pointer hover:bg-tifla-green/10 transition-all">
        {children}
    </div>
}

import { SUGGESTION_TYPE_TO_PROMPT, Suggestion, SuggestionList, SuggestionType } from "../components/Suggestions/Suggestion";
import { getSingleChatCompletion } from "./TiflaGPTApi";

const getAllSuggestionLists = async (types: SuggestionType[], initialSuggestion: Suggestion | undefined, inputText: string): Promise<SuggestionList[]> => {
    const promises: Promise<SuggestionList | null>[] = [];

    types.forEach((type) => {
        promises.push(getSuggestions(type, initialSuggestion, inputText));
    });

    const allSuggestionListResults = await Promise.all(promises);
    const allSuggestionLists: SuggestionList[] = [];

    if (allSuggestionListResults) {
        allSuggestionListResults.forEach((list, index) => {
            if (list) {
                allSuggestionLists.push(list);
            }
        });
    }

    return allSuggestionLists;
}

const chatCompletionToSuggestion = (type: SuggestionType, chatCompletion: string, inputText: string): Suggestion => {
    return {
        type: type,
        description: chatCompletion,
        title: ""
    }
}

const chatCompletionToSuggestions = async (type: SuggestionType, chatCompletion: string, inputText: string): Promise<Suggestion[]> => {
    const items = chatCompletion.split("\n").map((question: string) => question); //TODO better trimming
    const suggestions: Suggestion[] = items.map((item) => {
        return chatCompletionToSuggestion(type, item, inputText);
    });

    return suggestions;
}

const getSuggestions = async (
    type: SuggestionType,
    initialSuggestion: Suggestion | undefined,
    inputText: string): Promise<SuggestionList | null> => {
    let prompt=""

    // if (type===SuggestionType.JOURNEY) {
    //  prompt = SUGGESTION_TYPE_TO_PROMPT[type] + "Journaling intention is: " + initialSuggestion?.intention + "Journal entry so far is:" + inputText;
    // } else {
     prompt = SUGGESTION_TYPE_TO_PROMPT[type] + inputText;
    // } 
    const response = await getSingleChatCompletion(prompt);
    if (response) {
        return {
            type: type,
            suggestions: await chatCompletionToSuggestions(type, response, inputText)
        }
    }

    return null;

}

export { getAllSuggestionLists }
import React, { ComponentType, FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { EventsExtension, PlaceholderExtension, wysiwygPreset } from 'remirror/extensions';
import { TableExtension } from '@remirror/extension-react-tables';
import { EditorComponent, PositionerPortal, Remirror, ThemeProvider, usePositioner, useRemirror } from '@remirror/react';
import { FloatingToolbar } from '@remirror/react';
import { CreateEditorStateProps, PlainExtension } from 'remirror';
import type { RemirrorProps } from '@remirror/react';
import { Suggestion } from "../Suggestions/Suggestion";
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { DOMParser } from 'prosemirror-model';
import { MarkExtension, MarkExtensionSpec, ApplySchemaAttributes, MarkSpecOverride, NodeExtension, NodeExtensionSpec } from 'remirror';
import { NodeViewComponentProps } from '@remirror/react';
import UserCard from '../UserCard';
import { TextSelection } from 'prosemirror-state';

//creates a nodeview extension for usercard
export class UserCardExtension extends NodeExtension {
    static disableExtraAttributes = true;

    get name() {
        return 'user-card' as const;
    }

    createNodeSpec(): NodeExtensionSpec {
        return {
            content: 'block+',
            group: 'block',
            draggable: false,
            attrs: {
                content: { default: '' },
            },
            toDOM: (node) => {
                return ['div', { class: 'card', 'data-content': node.attrs.content }, 0];
            },
            parseDOM: [
                {
                    tag: 'div.card',
                    getAttrs: (dom) => {
                        const content = (dom as HTMLElement).dataset.content;
                        return { content };
                    },
                },
            ],
        };
    }
}


export const UserCardComponent: ComponentType<NodeViewComponentProps> = (props) => {
    const { node } = props;
    const content = node.textContent; // textContent will now contain the card content.
    return <UserCard content={content} />;
};

UserCardComponent.displayName = 'UserCardComponent';


//creates a demarker tag called "tifla"
export class TiflaMarkExtension extends MarkExtension {
    get name() {
        return 'tifla' as const;
    }

    createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
        return {
            ...override,
            attrs: extra.defaults(),
            parseDOM: [
                {
                    tag: 'span.tifla',
                    getAttrs: extra.parse,
                },
                ...(override.parseDOM ?? []),
            ],
            toDOM: (node) => {
                return ['span', { ...extra.dom(node), class: 'tifla' }, 0];
            },
        };
    }
}

const muiTheme = createTheme({
    palette: {
        background: {
            default: "transparent",
            paper: "transparent",
        }
    },
});

export interface ReactEditorProps
    extends Pick<CreateEditorStateProps, 'stringHandler'>,
    Pick<RemirrorProps, 'initialContent' | 'editable' | 'autoFocus' | 'hooks'> {
    placeholder?: string;
}

export class CustomPositionerExtension extends PlainExtension<{}> {
    get name() {
        return 'customPositioner' as const;
    }
}

interface WysiwygEditorProps extends Partial<ReactEditorProps> {
    setDropdownIndex: (index: number) => void;
    selectedDropdownValue: Suggestion | undefined;
    isLoading: boolean;
}

export const TextEditor: FC<PropsWithChildren<WysiwygEditorProps>> = (
    {
        placeholder,
        stringHandler,
        children,
        setDropdownIndex,
        selectedDropdownValue,
        isLoading,
        ...rest
    }) => {

    const [cursorOnBottom, setCursorOnBottom] = useState(false);
    const [dropdownDepth, setDropdownDepth] = useState(-1);
    const box = useRef<HTMLDivElement>(null);
    const [loadingPosition, setLoadingPosition] = useState<number | undefined>(undefined);

    const extensions = useCallback(
        () => [
            new PlaceholderExtension({ placeholder }),
            new EventsExtension(),
            new TableExtension(),
            new TiflaMarkExtension(),
            new UserCardExtension(),
            ...wysiwygPreset(),
        ],
        [placeholder],
    );

    const { manager } = useRemirror({ extensions, stringHandler });
    

    //main card function: takes in an html string and adds it in a card view in editor
    const addCard = (html: string) => {
        const currentState = manager.view.state;
        const endPosition = currentState.doc.content.size;
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        const node = DOMParser.fromSchema(manager.view.state.schema).parse(tempDiv);
        const userCardNode = manager.view.state.schema.nodes['user-card'].create({}, node.content);
        const transaction = currentState.tr.insert(endPosition, userCardNode);
        manager.view.dispatch(transaction);
        //remove the loading text
        if (loadingPosition !== undefined) {
            const currentState = manager.view.state;
            const transaction = currentState.tr.delete(loadingPosition, loadingPosition + 'Creating Personalised CBT Worksheet....'.length);
            manager.view.dispatch(transaction);
            setLoadingPosition(undefined); // reset the stored position
        };
    }


    //this function processes the worksheet text into the right content for card
    const addWorksheet = (dropdownValue: Suggestion | undefined) => {
        let newHTML = '';
        if (dropdownValue?.exerciseJSON) {
            const jsonStartIndex = dropdownValue.exerciseJSON.indexOf('{');
            const jsonString = dropdownValue.exerciseJSON.slice(jsonStartIndex);
            const parsedExerciseJSON = JSON.parse(jsonString);
            newHTML = parsedExerciseJSON.content;
            const div = document.createElement('div');
            div.innerHTML = newHTML;
            const steps = div.querySelectorAll('p');
            steps.forEach((p: HTMLParagraphElement, index: number) => {
                if (index !== 0) {
                    p.outerHTML = '<br/>' + p.outerHTML;
                }
            });
            newHTML = div.innerHTML;
            addCard(newHTML);
        }
    };

    const handleAddText = async (dropdownValue: Suggestion | undefined) => {
        let newText = '\n\n';

        if (isLoading) {
            newText = 'Creating Personalized CBT Worksheet...';
            const currentState = manager.view.state;
            setLoadingPosition(currentState.doc.content.size);
        } else if (!isLoading && dropdownValue?.exerciseJSON) {
            addWorksheet(dropdownValue);
        } else {
            newText = dropdownValue?.description.replace(/\d+\./g, '') || '';
        }

        if (!newText || newText.trim() === '') {
            return;
        }

        let currentState = manager.view.state;
        const endPosition = currentState.doc.content.size;
        const tiflaMark = currentState.schema.marks.tifla.create();
        const textNode = currentState.schema.text(newText, [tiflaMark]);
        let transaction = currentState.tr.insert(endPosition, textNode);
        manager.view.dispatch(transaction);

        // inserts a new line to close tag
        currentState = manager.view.state;
        const paragraphNode = currentState.schema.nodes.paragraph.createAndFill();
        if (paragraphNode) {
            transaction = currentState.tr.insert(currentState.doc.content.size, paragraphNode);
            manager.view.dispatch(transaction);
        }
        currentState = manager.view.state;
        const setSelectionTx = currentState.tr.setSelection(new TextSelection(currentState.tr.doc.resolve(currentState.doc.content.size - 1)));
        manager.view.dispatch(setSelectionTx);
        manager.view.focus();
    };

    useEffect(() => {
        handleAddText(selectedDropdownValue);
    }, [selectedDropdownValue, isLoading]);



    const onPositionY = (cursorY: number) => {
        if (box.current) {
            const { y, height } = box.current.getBoundingClientRect();
            if (cursorY > y + height - 30) {
                setCursorOnBottom(true);
                setDropdownDepth(-1);
            }
            else {
                setCursorOnBottom(false);
                setDropdownDepth(-1);
            }
        }
    }

    const onKeyDown = (event: React.KeyboardEvent) => {

        if (event.key === "ArrowDown") {
            if (cursorOnBottom) {
                setDropdownDepth((dropdownDepth) => dropdownDepth + 1);
            }
        }
        else if (event.key === "ArrowUp") {
            if (cursorOnBottom) {
                if (dropdownDepth > 0) {
                    event.preventDefault();
                    setDropdownDepth((dropdownDepth) => dropdownDepth - 1);
                }
                else {

                }

            }
        }
    }

    useEffect(() => {
        setDropdownIndex(dropdownDepth);
    }, [dropdownDepth, setDropdownIndex])

    useEffect(() => {
        manager.view.focus();
    }, [])

    return (
        <div ref={box} onKeyDown={onKeyDown} className="pr-8 sm:pr-0 pt-4">

            <ThemeProvider theme={{
                color: {
                    outline: "transparent",
                    border: "transparent"
                },

            }}>

                <MuiThemeProvider theme={muiTheme}>

                    <Remirror manager={manager} {...rest}>
                        { /*<EditorToolbar /> */}
                        <EditorComponent />
                        { /* <FloatingToolbar /> */}
                        { /* <TableComponents />  */}
                        {children}
                        <PositionerPortal>
                            <PositionerTest onPositionY={onPositionY} />
                        </PositionerPortal>
                    </Remirror>

                </MuiThemeProvider>
            </ThemeProvider>
        </div>
    );
};

const PositionerTest = (
    {
        onPositionY
    }: {
        onPositionY: (y: number) => void;
    }
) => {
    const { ref, x, y, width, height, active } = usePositioner('always');

    useEffect(() => {
        if (active) {
            onPositionY(y);
        }
    }, [y]);

    return (
        <div ref={ref} />
    );
}



import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GradientParams } from "./Gradient";
import * as THREE from 'three';
import vertex from "./shaders/vertex";
import fragment from "./shaders/fragment";

export class Sketch {
    scene: THREE.Scene;
    container: HTMLElement;
    renderer: THREE.WebGLRenderer;
    width: number;
    height: number;
    camera: THREE.PerspectiveCamera;
    //controls: OrbitControls;
    time: number;
    nextTime: number;
    interval: number = 0.001;
    isPlaying: boolean;
    material: THREE.ShaderMaterial;
    geometry: THREE.PlaneGeometry;
    plane: THREE.Mesh;
    dracoLoader: DRACOLoader;
    gltf: GLTFLoader;
    fromPalette: THREE.Color[];
    toPalette: THREE.Color[];
    colorFreq: number[];
    onAnimFinished: (params: GradientParams) => void;
    animationSpeed: number;

    constructor(dom: HTMLElement, params: GradientParams, _onAnimFinished: (params: GradientParams) => void, initPalette: THREE.Color[] ) {
        //console.log("new sketch", params, initPalette)
        this.onAnimFinished = _onAnimFinished;
        this.scene = new THREE.Scene();
        this.container = dom;
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.renderer.setSize(this.width, this.height);
        this.renderer.setClearColor(0xFFFFFF, 1);
        this.animationSpeed = 0.000005;
        //this.renderer.physicallyCorrectLights = true;
        //this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.container.appendChild(this.renderer.domElement);
        
        this.camera = new THREE.PerspectiveCamera(70, this.width / this.height, 0.001, 100);

        this.camera.position.set(0, 0, 0.5);
        //this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.time = params.time ? params.time : 0;
        this.nextTime = params.time + 0.000004;
        
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath('https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/');

        this.gltf = new GLTFLoader();
        this.gltf.setDRACOLoader(this.dracoLoader);

        this.isPlaying = true;
        this.fromPalette = initPalette;
        this.toPalette = initPalette;

        this.colorFreq= params.colorFreq

        this.material = new THREE.ShaderMaterial({
            extensions: {},
            side: THREE.DoubleSide,
            uniforms: {
                time: { value: this.time },
                nextTime: {value: this.nextTime},
                interval: {value: this.interval},
                resolution: { value: new THREE.Vector4 },
                uColor: { value: this.fromPalette},
                uColor2: {value: this.toPalette},
                colorFreq: {value: this.colorFreq},
            },
            wireframe: false,
            vertexShader: vertex,
            fragmentShader: fragment
        });


        this.geometry = new THREE.PlaneGeometry(2, 1, 300, 300);
        this.plane = new THREE.Mesh(this.geometry, this.material);
        this.plane.rotateX(Math.PI * -0.21);
        this.plane.translateZ(0.25);
        this.scene.add(this.plane);

        const light1 = new THREE.AmbientLight(0xffffff, 1);
        this.scene.add(light1);
        const light2 = new THREE.DirectionalLight(0xffffff, 1);
        light2.position.set(0.5, 0, 0.866);
        this.scene.add(light2);

        this.resize();
        this.render();
        this.setupResize();
    }

    updatePalette(newPalette: THREE.Color[]) {
        this.animationSpeed = 0.000002;
        this.fromPalette = [...this.toPalette]
        this.toPalette = [...newPalette];
        
        this.material.uniforms.uColor.value = this.fromPalette;
        this.material.uniforms.uColor2.value = this.toPalette;


        this.nextTime = this.time + this.interval;

        if (!this.isPlaying) {
            this.isPlaying = true;
            this.render();
        }
    }

    setupResize() {
        window.addEventListener('resize', this.resize.bind(this));
    }

    stop() {
        //console.log("stopping", this.time, (this.nextTime - this.time) / this.interval);
        this.isPlaying = false;
    }

    play() {
        //console.log(this.time)
        if (!this.isPlaying) {
            //console.log("playing", this.time, (this.nextTime - this.time) / this.interval);
            this.isPlaying = true;
            this.nextTime = this.time + this.interval;
            this.render();
        }
    }

    resize() {
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;
        this.renderer.setSize(this.width, this.height);
        this.camera.aspect = this.width / this.height;

        this.material.uniforms.resolution.value.x = this.width;
        this.material.uniforms.resolution.value.y = this.height;

        this.camera.updateProjectionMatrix();
        
    }

    shortAnimate() {
        this.nextTime = this.time + (this.interval );
        this.animationSpeed = 0.00002;
        this.isPlaying = true;
        this.render();
    }

    scroll(amount: number) {
        //console.log("scroll", amount)
        this.nextTime = this.time + (this.interval * amount);
        this.isPlaying = true;
        this.render();
    }

    render() {
        //console.log("render", (this.nextTime - this.time) / this.interval);

        if (!this.isPlaying) return;
        if (this.time >= this.nextTime) {
            this.stop();
            this.onAnimFinished(
                {time: this.time, colorFreq: this.colorFreq}
            );
            return;
        }
        let animationPct = (this.nextTime - this.time) / this.interval;
        let easedAnimationSpeed = animationPct < .1 ? Math.max(this.animationSpeed * .05, this.animationSpeed * animationPct * 10) : this.animationSpeed;
        this.time += easedAnimationSpeed;
        
        //console.log(this.time, this.nextTime);
        this.material.uniforms.time.value = this.time;
        this.material.uniforms.nextTime.value = this.nextTime;
        this.renderer.render(this.scene, this.camera);
        setTimeout(() => {
            requestAnimationFrame(this.render.bind(this));
          }, 1000 / 30);
        //window.requestAnimationFrame(this.render.bind(this));

    }


}
import { Suggestion, SuggestionList, SuggestionType } from "src/components/Suggestions/Suggestion"
import { SuggestionListCarousel } from "./SuggestionListCarousel"

const GRATITUDE_QUESTIONS = [
    "What is one moment from today that brought a smile to my face?",
    "Who made a positive difference in my day today, no matter how small?",
    "What is something I have today that I didn't have a year ago, that I am truly grateful for?",
    "Can I think of a challenge or difficulty that I faced recently? How can I find aspects of it to be grateful for or lessons that it taught me?",
    "If my personal growth was a video game, what 'level up' or progress would I celebrate most from the recent times?",
    "If my day was a movie, what scene would I rewind and play over because it brought me so much joy or peace?",
    "What personal strength or quality did I utilize today that I am proud of?",
    "Imagine a 'Gratitude Gala' where you are to present an award. Who in your life gets the 'Biggest Positive Impact' award and why",
    "If I were to write a superhero comic about myself, what superpower or personal trait would I be celebrating?",
    "Pretend you're an alien who just landed on Earth. What human invention are you most grateful for and why?",
    "How can I express gratitude for the natural environment around me, such as the sun, the breeze, or the sound of birds?"
]
    

export const GratidueThemeItem = (
    {
        onSelect,
    } : {
        onSelect: (target: Suggestion | null) => void,
    }
) => {
    let suggestions: Suggestion[] = [];
    
    GRATITUDE_QUESTIONS.forEach((question, index) => {
        suggestions.push({
            type: SuggestionType.GRATITUDE,
            title: "Gratitude & Positivity",
            theme: "gratitude",
            description: question,
        })
    })    

    const suggestionList: SuggestionList = {
        type: SuggestionType.GRATITUDE,
        suggestions: suggestions,
    }

    return <SuggestionListCarousel suggestionList={suggestionList} onSelect={onSelect}/>
}
import { useEffect, useRef, useState } from "react";
import { RightArrow } from "../Icons";
import { useInView } from "framer-motion";

export const FeaturePrompt = () => {

    const [animationStep, setAnimationStep] = useState<number>(-1);
    const {text, finished} = useTypingText("I am feeling overwhelmed at work, too much to do and not enough time, I just want to quitt", 10, animationStep === 0);
    const ref = useRef(null);
    const slideRef = useRef(null);
    const isInView = useInView(ref);

    useEffect(() => {
        if (isInView) {
            setAnimationStep(0);
        }
        else {
            setAnimationStep(-1);
        }
    }, [isInView])

    useEffect(() => {
        if (finished === true) {
            setAnimationStep(1);
        }
    }, [finished])

    useEffect(() => {
        console.log("animationStep", animationStep);
        switch (animationStep) {
            case 1: setTimeout(() => setAnimationStep(2), 1500); break;
            case 2: setTimeout(() => setAnimationStep(3), 1500); break;
            
        }
        if (slideRef.current) {
            (slideRef.current as any).style.transform = `translateX(-${(animationStep - 1) * 33.33}%)`;
        }
    }, [animationStep])

    return <div className="w-full flex flex-col space-y-8 items-center pt-36">
        <div className="text-5xl font-garamond text-center">
            Thoughtful Prompts
        </div>
        <div className="max-w-[700px] text-center">
            Amble provides journaling prompts based on what you've written in your entry so far, to help you unpack your thoughts and reflect on your day.
        </div>

        <div className="text-xl w-full sm:w-[600px] min-h-[300px] sm:min-h-[210px] bg-white/30 p-8 rounded-xl flex flex-col justify-between">
            <div><span>{text}</span><span className={`${animationStep > 0 ? "blinking-cursor" : "hidden"}`}>|</span></div>
            <div className={`transition-all duration-[2000ms] ${animationStep > 0 ? "opacity-100" : "opacity-0"}`}>
                <div className="w-full overflow-hidden">
                    <div ref={slideRef} className={`w-[300%] flex flex-row transition-all duration-[1000ms]`}>
                        <PromptSlide text="What specific tasks at work are causing you the most overwhelm?"/>
                        <PromptSlide text="Can you identify any factors outside of work that might be contributing to this feeling?"/>
                        <PromptSlide text="How do you typically cope with moments of stress?"/>
                    </div>
                </div>    
            </div>
        </div>
        <div ref={ref} />
    </div>
}

const PromptSlide = ({text}: {text: string}) => {
    return <div className="w-full pr-2">
        <div className="w-full rounded-lg border border-tifla-green flex flex-row justify-between items-center p-2 ">
            <div className="flex flex-col">
                <div className="text-sm font-bold">PROMPT</div>
                <div>{text}</div>
            </div>
            <div className="pr-2">
                {RightArrow}
            </div>
        </div>
        
    </div>
}


const useTypingText = (text: string, delay: number, animate: boolean) => {
    const [typingText, setTypingText] = useState<string>("");
    const [typingIndex, setTypingIndex] = useState<number>(0);

    useEffect(() => {
        if (animate === false) {
            setTypingIndex(0)
        }
    }, [animate])

    useEffect(() => {
        if (!animate) return;

        const interval = setInterval(() => {
            setTypingText(text.substring(0, typingIndex));
            setTypingIndex(typingIndex + 1);
        }, delay);

        return () => clearInterval(interval);
    }, [typingIndex, animate]);

    return {text: typingText, finished: typingIndex >= text.length};
}
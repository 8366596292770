import { addDoc, collection } from 'firebase/firestore';
import { db }  from '../firebase';

const createUser = async (uid: string) => {
    try {
        const docRef = await addDoc(collection(db, "users"), {
            uid: uid,
        });

        console.log("Document written with ID: ", docRef.id);

    } catch(e) {
        console.error("Error adding document: ", e)

    }

}

export {createUser}
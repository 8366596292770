
import { Suggestion } from "../Suggestions/Suggestion";
import { useNavigate } from "react-router-dom";
import { createNewEntry } from "src/lib/useEntry";
import { getGreeting } from "src/lib/getGreeting";
import { ContinueEntry } from "./ContinueEntry";
import { NewEntry } from "./NewEntry";
import { motion } from "framer-motion";
import Footer from "../Footer";
import { useContext } from "react";
import { AuthContext } from "src/App";

const HomeLanding = () => {
    const navigate = useNavigate();
    const {currentUser} = useContext(AuthContext);

    const newEntrySuggestion = (suggestion: Suggestion, theme:string) => {
        createNewEntry(currentUser.uid).then((docRef) => {
            navigate(`/entry/${docRef.id}`, { state: { suggestion: suggestion, theme: theme } });
        }).catch((error) => {
            throw error;
        });
    }

    return <div className={`container max-w-[900px] w-auto
                            sm:mx-auto mx-4`}>
        <motion.div className="pt-8 sm:pt-24" initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 2}}>
            <h1 className="font-garamond text-6xl text-tifla-green"><b> {getGreeting(currentUser.uid)} </b></h1>
        </motion.div>

        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 2, delay: 1}}>
            <ContinueEntry uid={currentUser.uid} />
        </motion.div>

        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 2, delay: 2}}>
            <NewEntry uid={currentUser.uid} onNewEntrySuggestion={newEntrySuggestion}/>
        </motion.div>

        <div className="h-48" />
        <Footer />
    </div>
};



export default HomeLanding;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAw5nmpIQAlIKT2zpfkNaedu2giz4EsxIw",
  authDomain: "tifla-a47b3.firebaseapp.com",
  projectId: "tifla-a47b3",
  storageBucket: "tifla-a47b3.appspot.com",
  messagingSenderId: "235436044121",
  appId: "1:235436044121:web:6f884954e92d7c39f7a648",
  measurementId: "G-WP27WP4R05",
  storageBucket: "gs://tifla-a47b3.appspot.com/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
//connectFunctionsEmulator(functions, "localhost", 5001);
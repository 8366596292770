import { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AuthContext } from "src/App";
import { EntryEditor2 } from "src/components/EntryEditor/EntryEditor2";
import { Suggestion } from "src/components/Suggestions/Suggestion";
import { useEntry } from "src/lib/useEntry";

// Edit an entry that already exists
const Entry = () => {
    const {currentUser} = useContext(AuthContext);
    const entryId = useParams().entryId;
    const {state} = useLocation();
    const suggestion = state?.suggestion;
    const theme = state?.theme;
    window.history.replaceState({}, document.title)
    
    return <div className="w-full">
        { entryId && <EntryWrapper 
            uid={currentUser.uid} 
            entryId={entryId}
            suggestion={suggestion}
            theme={theme}/>}
    </div>

};

const EntryWrapper = (
    { 
        uid,
        entryId,
        suggestion,
        theme,
    } : {
        uid: string,
        entryId: string,
        suggestion?: Suggestion,
        theme?: string,
    }) => {

    const initialEntry = useEntry(uid, entryId);

    return <div className="w-full">
        {initialEntry && 
            <EntryEditor2 
                entry={initialEntry}
                initialSuggestion={suggestion}
                selectedTheme={theme}/>
        }

    </div>
}

export { Entry }
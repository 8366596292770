import { Suggestion, SuggestionList, SuggestionType } from "src/components/Suggestions/Suggestion"
import { SuggestionListCarousel } from "./SuggestionListCarousel"

const MINDFULNESS_QUESTIONS = [
    "How are you feeling right now? Don't worry about making it sound 'right' -- just get it out there.",
    "What was the high point and low point of your day? What emotions did you feel during those times?",
    "How is your body feeling? Do you notice any connection between your physical sensations and your emotions?",
    "Is there something you've been avoiding thinking about? Writing it down might help you to process it.",
    "What's a situation that you're unsure about how you feel? Try to explore your mixed emotions.",
    "Is there something you wish you could say to someone but haven't? How does it feel to write it down?",
    "When did you last feel truly at peace or joyful? Describe the moment in as much detail as possible.",
    "What's one simple thing you could do, right now, to help yourself feel a bit better or more centered?",
    "What's an emotion you struggle to put into words? Try to describe it in terms of colors, images, or metaphors.",
    "What's been on your mind lately? Are there any recurring thoughts?"
]
    

export const MindfulnessThemeItem = (
    {
        onSelect,
    } : {
        onSelect: (target: Suggestion | null) => void,
    }
) => {
    let suggestions: Suggestion[] = [];
    
    MINDFULNESS_QUESTIONS.forEach((question, index) => {
        suggestions.push({
            type: SuggestionType.MINDFULNESS,
            title: "Mindfulness",
            theme: "mindfulness",
            description: question,
        })
    })    

    const suggestionList: SuggestionList = {
        type: SuggestionType.MINDFULNESS,
        suggestions: suggestions,
    }

    return <SuggestionListCarousel suggestionList={suggestionList} onSelect={onSelect}/>
}
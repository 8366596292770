import { User, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../firebase";

type TiflaUser = {
    email: string,
    uid: string
}

const useCurrentUser = (): {user: TiflaUser | null, loading: boolean} => {
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<TiflaUser| null>(null);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(
                    {
                        email: user.email ? user.email : "",
                        uid: user.uid
                    })
                setLoading(false);
            } else {
                setUser(null);
                setLoading(false);
            }
        });
    }, [])

    return {user, loading};
}

export { useCurrentUser }
import * as THREE from "three"

const NUM_COLORS = 5;

export const pickGradientColors = (allColors: THREE.Color[]): THREE.Color[] => {
    if (allColors.length < NUM_COLORS) {
        return [...allColors, ...defaultPalette().slice(0, NUM_COLORS - allColors.length)]
    }
    else {
        return [...allColors.slice(allColors.length - NUM_COLORS)]
    }
}

const randomLightPalette = () => {
    return [
        new THREE.Color(`hsl(${Math.random() * 360}, 70%, 70%)`),
        new THREE.Color(`hsl(${Math.random() * 360}, 70%, 70%)`),
        new THREE.Color(`hsl(${Math.random() * 360}, 70%, 70%)`),
        new THREE.Color(`hsl(${Math.random() * 360}, 70%, 70%)`),
        new THREE.Color(`hsl(${Math.random() * 360}, 70%, 70%)`),
    ]
}

const defaultPalette = () => {
    return [
        new THREE.Color(0xFFFFFF),
        new THREE.Color(0xFFFFFF),
        new THREE.Color(0xFFFFFF),
        new THREE.Color(0xFFFFFF),
        new THREE.Color(0xFFFFFF),
    ]
}

export { defaultPalette, randomLightPalette }
import { useState } from "react";
import { RemirrorJSON } from "remirror";
import extractOriginalEntry from "./extractOriginalEntry";

const useLengthDebounce = (value: RemirrorJSON, interval: number) => {
   const originalText = extractOriginalEntry(value);
   const [debouncedValue, setDebouncedValue] = useState<string>(originalText);
   if (originalText && (originalText.length >= interval && originalText.length > debouncedValue.length + interval || originalText.length < debouncedValue.length - interval)) {
        setDebouncedValue(originalText);
        //console.log("original text is" + debouncedValue + "and the length is" + originalText.length)
    }
    
    return debouncedValue;

};

export {useLengthDebounce}



import {
    Navigate,
    Route,
    Routes,
} from "react-router-dom";
import { useCurrentUser } from "./lib/useCurrentUser";
import { Entry } from "./routes/Entry";
import React, { useContext, useEffect } from "react";
import { Signup } from "./routes/auth/signup";
import { Login } from "./routes/auth/login";
import { Entries } from "./routes/Entries";
import Header from "./components/Navigation/Header";
import LandingPage from "./components/LandingPage/LandingPage";
import HomeLanding from "./components/HomePage/HomeLanding";

const App = () => {

    return <div className={"font-quicksand w-full"}>
        <AuthProvider>
            <div className="w-full">
                <Header fadeCount={0} fadeInCount={0} />
                <Routes>
                    <Route index
                        element={<LandingPage />} />
                    
                    <Route
                        path="home"
                        element={
                                <RequireAuth>
                                    <HomeLanding />
                                </RequireAuth>}
                    />

                    <Route
                        path="entry/:entryId"
                        element={
                                <RequireAuth>
                                    <Entry />
                                </RequireAuth>}
                    />

                    <Route
                        path="viewall"
                        element={
                                <RequireAuth>
                                    {<Entries />}
                                </RequireAuth>
                        } />

                    <Route
                        path="signup"
                        element={<Signup /> } />

                    <Route
                        path="login"
                        element={<Login /> } />

                </Routes>
            </div>
        </AuthProvider>
    </div>
}

interface AuthContextType {
    currentUser: any;
    loading: boolean;
}

const AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = (
    {
        children
    }: {
        children: JSX.Element
    }) => {

    const { user: currentUser, loading } = useCurrentUser();
    const value = { currentUser, loading }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const ReShowNavbar = (
    {
        children,
        showNavbar,
    }: {
        children: JSX.Element,
        showNavbar: () => void
    }) => {

    useEffect(() => {
        showNavbar();
    }, [])

    return children;
}

const RequireAuth = (
    {
        children
    }: {
        children: JSX.Element
    }) => {

    const { currentUser, loading } = useContext(AuthContext);
    if (loading) {
        return <div>Loading</div>
    }
    else if (!currentUser) {
        return <Navigate to="/login" />
    }

    return children;
}

const RequireNoAuth = (
    {
        children
    }: {
        children: JSX.Element
    }) => {
    const { currentUser, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (currentUser) {
        return <Navigate to="/" />;
    }

    return children;
}



export { App, AuthContext }
import { Suggestion, SuggestionType } from "../Suggestions/Suggestion"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { PersonalizedBox } from "../PersonalisedBox"
import { WeeklyBox } from "../WeeklyBox"

export const NewEntry = (
    {
        uid,
        onNewEntrySuggestion
    } : {
        uid: string,
        onNewEntrySuggestion: (suggestion: Suggestion, theme: string) => void
    }
) => {
    return (
        
        <div className="">
            <div className="text-2xl font-semibold mt-8 text-tifla-green">
                Start A New Entry
            </div>
            
            <div className="mt-4">
                <Tabs>
                    <TabList className="pl-0 flex space-x-4">
                        <Tab className="list-none text-left rounded-md text-xl font-semibold text-tifla-green cursor-pointer" selectedClassName="underline">Featured</Tab>
                        <Tab className="list-none text-left rounded-md text-xl font-semibold text-tifla-green cursor-pointer" selectedClassName="underline">For You</Tab>
                    </TabList>
                    <div className="mt-4">
                        <TabPanel>
                            <SuggestionsBox onSelect={onNewEntrySuggestion}/>
                        </TabPanel>
                        <TabPanel>
                            <div className="flex flex-row w-full -ml-4">
                                <PersonalizedBox onSelect={onNewEntrySuggestion} uid={uid}/>
                                <WeeklyBox onSelect={onNewEntrySuggestion} uid={uid}/>
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </div>
    
      )
}


const SuggestionsBox = (
    {
        onSelect,

    }: {
        onSelect: (sugestion: Suggestion, theme: string) => void
    }
) => {
    const useSuggestions = (): Suggestion[] => {
        let suggestions: Suggestion[] = [];
        suggestions.push({
            type: SuggestionType.FOLLOW_UP_QUESTIONS,
            title: "Start Journaling",
            theme: "default",
            cardPrompt: "Start a blank canvas to process your thoughts",
            description: ""
        });

        suggestions.push({
            type: SuggestionType.FOLLOW_UP_QUESTIONS,
            title: "Gratitude & Positivity",
            theme: "gratitude",
            cardPrompt: "Nurture an attitude of gratitude and thankfulness",
            description: ""
        });

        suggestions.push({
            type: SuggestionType.FOLLOW_UP_QUESTIONS,
            title: "Mindful Emotions",
            theme: "mindfulness",
            cardPrompt: "Explore complex emotions from a place of mindfulness & self-compassion",
            description: ""
        });
        
        return suggestions;
    }

    return <div className="flex flex-row flex-wrap w-full -ml-4 h-auto">
        {useSuggestions().map((suggestion, index) => <SuggestionCard key={index} suggestion={suggestion} onClick={() => { onSelect(suggestion, suggestion.theme ? suggestion.theme : "default") }} />)}
    </div>
}

export const SuggestionCard = (
    {
        suggestion,
        onClick,
    }:
        {
            suggestion: Suggestion,
            onClick: () => void
        }
) => {

    return <div
    key={suggestion.title}
    className="w-full sm:w-1/3 h-60 pl-4 py-2 cursor-pointer hover:scale-105 transition-all duration-500 text-tifla-green"
    onClick={onClick}>
    
    <div className="h-full text-center rounded-lg overflow-hidden shadow-md bg-white h-60">
        <div className="p-6">
            <div className="w-full text-3xl font-garamond mb-2 h-12">
                {suggestion.title}
            </div>
            <div className="w-full text-md h-20 my-10">
                {suggestion.cardPrompt}
            </div>
        </div>
    </div>
</div>
}
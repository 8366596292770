import { Entry } from "src/components/EntryEditor/Entry";
import { isYesterday } from 'date-fns';
import proseMirrorToString from "src/lib/proseMirrorToString";

interface Props {
  entries: Entry[];
}

const getLastEntryFromYesterday = ({ entries }: Props): Entry | null => {
  const yesterdayEntries = entries.filter((entry) =>
    isYesterday(new Date(entry.date_created)) && proseMirrorToString(entry.pmJSON)!== "");
  return yesterdayEntries.length > 0 ? yesterdayEntries[0] : null;
};

export default getLastEntryFromYesterday;


type Feeling = {
    name: string,
    percent: number | null,
    color: string,
}

//merges two list of feelings, prioritizing the first and not overwriting

const mergeFeelings = (feelings: Feeling[], newFeelings: Feeling[]): Feeling[] => {
    var mergedFeelings: Feeling[] = [...feelings];
    
    newFeelings.forEach((newFeeling) => {
        if (!feelings.some((feeling) => feeling.name === newFeeling.name)) {
            mergedFeelings.push(newFeeling);
        }
    });

    return mergedFeelings;
}



export type { Feeling };
export {mergeFeelings}
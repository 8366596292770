import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import reflection1 from "../../img/reflection1.png"
import reflection2 from "../../img/reflection2.png"
import reflection3 from "../../img/reflection3.png"

export const FeatureVisualization = () => {
    
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [animationStep, setAnimationStep] = useState<number>(-1);

    useEffect(() => {
        if (isInView) {
            setAnimationStep(0);
        }
        else {
            setAnimationStep(-1);
        }
    }, [isInView])

    useEffect(() => {
        switch(animationStep) {
            case 0: 
                setTimeout(() => setAnimationStep(1), 1200); break;
            case 1: 
                setTimeout(() => setAnimationStep(2), 500); break;
            case 2: 
                setTimeout(() => setAnimationStep(3), 500); break;
            case 3: 
                setTimeout(() => setAnimationStep(4), 500); break;
        }
    }, [animationStep])

    const getButton = () => {
        switch(animationStep) {
            case 0: return "Generate Visualization";
            case 1: return "Generating...";
            case 2: return "Generating...";
            return "";
        }
    }

    return <div className="w-full flex flex-col space-y-8 items-center pt-48">
        <div className="text-5xl font-garamond">
            Visualization
        </div>
        <div className="max-w-[700px] text-center">
            Use AI to create a visualization of your entry, allowing you to reflect on it in a new way.
        </div>

        
        <div className="flex flex-col transition-all duration-[1000ms] space-x-4">
            <div className="rounded-lg bg-white/30 p-4 w-full sm:w-[600px]">
                <div className="text-xl mb-2">
                    <span>
                        Today I'm feeling really inspired and energetic.
                    </span><span className="blinking-cursor">|</span>
                </div>
                <div className="w-full flex flex-row space-x-4 relative -ml-4">
                    <div className={`absolute left-[50%] top-[50%] -z-10 ${animationStep < 1 ? "opacity-100" : "opacity-0"}`}>
                        <div className="loader"/>
                    </div>
                    <ImageThumb src={reflection1} show={animationStep >= 1}/>
                    <ImageThumb src={reflection2} show={animationStep >= 2}/>
                    <ImageThumb src={reflection3} show={animationStep >= 3}/>
                </div>
            </div>
            
        </div>
        
        
        <div ref={ref} />
        
    </div>
}

export const ImageThumb = (
    {
        src,
        show
    } :
    {
        src : string,
        show: boolean
    }
) => {
    return <div className="w-1/3 p-2">
        <img className={`rounded-lg transition-all duration-[1000ms] ${show ? "opacity-100" : "opacity-0"}`} src={src}/>
    </div>
}
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from "src/App";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import { signOut } from 'firebase/auth';
import { auth } from 'src/firebase';
import { CloseIcon, MenuIcon } from '../Icons';

function Header(
    {
        fadeCount,
        fadeInCount,
    } : {
        fadeCount: number,
        fadeInCount: number,
    }
) {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser, loading } = useContext(AuthContext);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [showNavbar, setShowNavbar] = useState(true);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const scrollHandler = (e:any) => {
        if (window.scrollY >= 1) {
            setShowNavbar(false);
        } else {
            setShowNavbar(true);
        }
    }

    const mouseMoveHandler = (e:MouseEvent) => {
        if (e.pageY < 100) {
            setShowNavbar(true);
        }
        else if (e.pageY > 100 && e.movementY > 10) {
            setShowNavbar(false);
        }

    }

    useEffect(() => {
        setShowNavbar(false);
    }, [fadeCount])

    useEffect(() => {
        setShowNavbar(true);
    }, [fadeInCount])

    // set an interval to update the current time every second
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDate(new Date());
        }, 60_000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        window.addEventListener("mousemove", mouseMoveHandler);
        
        return () => {
            window.removeEventListener("scroll", scrollHandler);
            window.removeEventListener("mousemove", mouseMoveHandler);
        }
    }, [])

    const formattedDate = `${currentDate.toLocaleDateString([], {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    })}`;
    
    if (location.pathname === "/" || (!loading && !currentUser)) {
        return <></>
    }

    return <div>
            <div className="h-20"/>
            <motion.div 
                className="text-tifla-green flex h-16 fixed w-full top-0 py-4 r"
                initial={{ opacity: 1}}
                animate={showNavbar ? { opacity: 1} : { opacity: 0}}
                transition={{ duration: 0.5 }}>

                <div className="px-6 w-full flex justify-between items-center">
                    <div className="w-2/5 hidden sm:flex flex-row "> {/* left side */}
                        <UserHeaderItem currentUser={currentUser} />
                        
                        <div className="mr-4 cursor-pointer hover:underline" onClick={() => { navigate("/viewall") }}>Past Entries</div>
                    </div>
                    
                    {/* middle */}
                    <div className="w-1/5 text-center cursor-pointer" onClick={() => {navigate("/home")}}>
                        <h1 className="text-2xl font-bold hover:scale-110 hover:tracking-widest transition-all duration-1000">AMBLE</h1>
                    </div>

                    {/* right side */}
                    <div className="w-2/5 hidden sm:flex flex-row justify-end "> 
                        <div className="mr-6">{formattedDate}</div>
                        <div className="">{currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                    </div>
                    < div className="sm:hidden" onClick={() => {setMobileMenuOpen(mobileMenuOpen => !mobileMenuOpen)}}>
                        {mobileMenuOpen ? CloseIcon : MenuIcon}
                    </div>
                </div>
                <MobileMenu open={mobileMenuOpen} toggleOpen={() => {setMobileMenuOpen(mobileMenuOpen => !mobileMenuOpen)}} />
            </motion.div>
        </div>
}

const UserHeaderItem = (
    {
        currentUser
    } : {
        currentUser: any
    }
) => {

    const [dropownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    if (!currentUser) {
        return <></>
    }

    return <div className="flex flex-col relative">
        <div className="mr-6 cursor-pointer hover:underline"
            onClick={() => {setDropdownOpen(dropownOpen => !dropownOpen)}}>{currentUser.email}</div>    

        {dropownOpen && <div className="cursor-pointer absolute top-[100%] w-full"
            onClick={()=> {
                signOut(auth)
                    .then(() => {
                        console.log("sign out successful")
                        navigate("/");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }}>Logout</div>
        }   
    </div>
}

const MobileMenu = (
    {
        open,
        toggleOpen
    } : {
        open: boolean,
        toggleOpen: () => void
    }
) => {
    const navigate = useNavigate();

    return <div className={`absolute w-full h-[100vh] bg-background-paper flex flex-col space-y-2 sm:hidden text-center mt-12 transition-all duration-500 z-60
                ${open ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}`}>
        <div onClick={() => {navigate("/viewall")}}>
            Past Entries
        </div>
        <div onClick={() => {
            signOut(auth)
                .then(() => {
                    console.log("sign out successful")
                    toggleOpen();
                    navigate("/");
                })
                .catch((error) => {
                    console.log(error);
                });
        }}>
            Logout
        </div>
    </div>
}


export default Header;

import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="w-full text-tifla-green py-4 px-6 bottom-0">
      <p className="text-center">
        &copy; Amble. All rights reserved. Submit app feedback <a href="https://tazs7mxpndu.typeform.com/to/QUmdS3oV" className="underline text-tifla-green">here</a>
      </p>
    </footer>
  );
}

export default Footer;

import { CloudSaved, CloudSaving, CloudUnsaved } from "../Icons"

enum AutosaveStatus {
    UNSAVED,
    SAVING,
    SAVED
}

const AutosaveStatusIndicator = ({status}: {status: AutosaveStatus}) => {
    const getIcon = () => {
        switch (status) {
            case AutosaveStatus.UNSAVED:
                return CloudSaving
            case AutosaveStatus.SAVING:
                return CloudSaving
            case AutosaveStatus.SAVED:
                return CloudSaved
        }
    }


    return <div className="fill-neutral-400 stroke-neutral-400">
        {getIcon()}
    </div>
}

export {AutosaveStatus, AutosaveStatusIndicator}
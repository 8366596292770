import type { RemirrorJSON } from '@remirror/core';
import { DocumentData, DocumentReference, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from 'firebase/firestore';
import { SidebarModule } from './Sidebar/SidebarModule';
import { GradientParams } from '../Gradient/Gradient';
import { SuggestionList } from '../Suggestions/Suggestion';

type Entry = {
    docId: string,
    ref: DocumentReference<DocumentData>,
    text: string,
    date_created: Date,
    pmJSON: RemirrorJSON,
    sidebarModules: SidebarModule[],
    gradientParams: GradientParams,
    summary: string,
    wrappedUp: boolean,
    suggestions: SuggestionList[],
    reflectionImagePath: string,
    theme: string,
}

const entryConverter: FirestoreDataConverter<Entry> = {

    toFirestore(entry: WithFieldValue<Entry>): DocumentData {
        return {
            ...entry
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Entry {
        const data = snapshot.data(options);
        return {
            docId: snapshot.id,
            ref: snapshot.ref,
            text: data.text,
            date_created: data.date_created ? data.date_created.toDate() : undefined,
            pmJSON: data.pmJSON,
            sidebarModules: data.sidebarModules ? data.sidebarModules : [],
            gradientParams: data.gradientParams ? data.gradientParams : undefined,
            summary: data.summary ? data.summary : undefined,
            wrappedUp: data.wrappedUp ? data.wrappedUp : false,
            suggestions: data.suggestions ? data.suggestions : undefined,
            reflectionImagePath: data.reflectionImagePath ? data.reflectionImagePath : undefined,
            theme: data.theme ? data.theme : undefined,
        };
    },
};

export type { Entry };
export { entryConverter };
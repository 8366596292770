import { useLatestEntry, useLatestEntryFromToday } from "src/lib/useCurrentEntry";
import { findFeelingsModuleData } from "../EntryEditor/Sidebar/FeelingsModule/FeelingsModule";
import { Pencil } from "../Icons";
import { useNavigate } from "react-router-dom";
import { GradientThumb } from "../Gradient/GradientThumb";
import proseMirrorToString, { proseMirrorToStringExcludeTifla } from "src/lib/proseMirrorToString";
import { Suggestion } from "../Suggestions/Suggestion";

export const ContinueEntry = (
    {
        uid,
    } : {
        uid: string
    }
) => {
    const { entry: entry, loading } = useLatestEntry(uid);
    const navigate = useNavigate();

    if (entry && entry.wrappedUp === true) {
        return <div></div>
    }

    const feelingsModuleData = entry ? findFeelingsModuleData(entry.sidebarModules) : undefined;

    var entryText = entry ? proseMirrorToStringExcludeTifla(entry.pmJSON) : "";
    var endOfEntry = entryText.substring(entryText.length - 150, entryText.length);
    endOfEntry = "..." + endOfEntry.substring(endOfEntry.indexOf(" ") + 1, endOfEntry.length);

    return <div className="flex flex-col">
        { loading && <div>Loading</div>}
        { entry && <div>
            <div className="text-2xl font-semibold mt-8 text-tifla-green mb-2">
                Continue journaling
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-2">
                <div className="w-full sm:w-1/3 sm:pr-4">
                    <div className="w-full rounded-md h-96 overflow-hidden cursor-pointer"
                         onClick={() => {navigate(`/entry/${entry.docId}`)}}>
                        
                        <GradientThumb params={entry.gradientParams} feelingsModuleData={feelingsModuleData} />
                        
                        <div className="flex flex-col justify-between p-6 h-full">
                            <div>    
                                <div className="text-2xl font-semibold font-quicksand text-tifla-green mb-6">
                                    {"Today, " + entry.date_created.toLocaleString(window.navigator.language, {month: 'long'}) + " " + entry.date_created.getDate() }
                                </div>

                                <div className="text-lg leading-6">
                                    {endOfEntry}
                                </div>
                            </div>
                            
                            <div className="border border-tifla-green rounded-full py-2 font-medium hover:bg-tifla-green/20" 
                                onClick={() => {navigate(`/entry/${entry.docId}`)}} >
                                <div className="flex flex-row items-center justify-center space-x-2 text-xl">{Pencil} <div>Continue</div></div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                <div className="w-2/3 flex flex-col justify-between py-2 pr-4">
                    <div className="flex flex-col space-y-2 -mt-2">
                        <div>
                            Suggestions for this entry
                        </div>
                        {entry.suggestions && entry.suggestions[0] && 
                            entry.suggestions[0].suggestions.map(
                                suggestion =>
                                    <ContinueSuggestion 
                                        key={suggestion.description} 
                                        suggestion={suggestion}
                                        onSelect={() => {
                                            navigate(`/entry/${entry.docId}`, {state: {suggestion: suggestion}})
                                        }} />
                            )
                            }
                    </div>
                </div>
            </div>
        </div> }
    </div>
}

const ContinueSuggestion = (
    {
        suggestion,
        onSelect
    } : {
        suggestion: Suggestion,
        onSelect: () => void
    }) => {
    return <div className="flex flex-col rounded-lg border border-tifla-green p-4 cursor-pointer hover:bg-tifla-green/10"
                onClick={onSelect}>
        <div className="text-sm font-medium">
            {suggestion.type}
        </div>
        <div className="leading-5">
            {suggestion.description}
        </div>
        
    </div>
}
import { useEffect, useState } from "react";
import { RemirrorJSON } from "remirror";
import { getSingleChatCompletion } from "src/lib/TiflaGPTApi";
import { useLengthDebounce } from "src/lib/useLengthDebounce";
import { FeelingsModuleData } from "./Sidebar/SidebarModule";
import { Feeling } from "./Sidebar/FeelingsModule/Feeling";
import { BasicButton } from "../BasicButton";
import { motion, } from "framer-motion";
import { GenerateImage } from "./GenerateImage";
import { topFeelings } from "./Sidebar/FeelingsModule/FeelingsModule";

export const Wrapup = (
    {
        uid,
        show,
        currentSummary,
        updateSummary,
        journalPMJSON,
        feelingsModuleData,
        onKeepJournaling,
        saveImage,
        reflectionImagePath
    } : {
        uid: string,
        show: boolean
        currentSummary: string,
        updateSummary: (summary: string) => void,
        journalPMJSON: RemirrorJSON,
        feelingsModuleData: FeelingsModuleData
        onKeepJournaling: () => void,
        saveImage: (full_path: string) => Promise<void>,
        reflectionImagePath: string
    }
) => {

    const debouncedText = useLengthDebounce(journalPMJSON, 100);
    const [firstLoad, setFirstLoad] = useState(true);
    const topThreeFeelings = topFeelings(feelingsModuleData);

    useEffect(() => {
        if (debouncedText) {
            if (firstLoad) {
                setFirstLoad(false);
                return;
            }
            else {
                const prompt = "Identify up to 3 topics in this journal entry in a bulleted list, each less than 5 words: " + debouncedText;
                getSingleChatCompletion(prompt).then((response) => {
                    if (response) {
                        updateSummary(response);
                    }
                    
                }).catch((error) => {
                    console.log(error);
                });
            }
            
        }
    }, [debouncedText])

    const variants = {
        visible: {
            opacity: 1,
        },
        hidden: {
            opacity: 0,
        }
    }

    return <motion.div
        variants={variants} 
        animate={show ? "visible" : "hidden"}
        transition={{ delay: 0.2, duration: 2 }}
        className={`${show ? "" : "hidden"}`}>
        <div className="w-full mt-8 pb-48 border-t-2 border-tifla-green flex flex-col items-start space-y-3">
            <div className="text-5xl pt-8 font-garamond">
                Entry Summary
            </div>
            <div className="w-full flex flex-row pt-4 pb-4">
                <div className="w-1/2">
                    <TopicsSummary summary={currentSummary}/>
                </div>
                <div className="w-1/2">
                    <FeelingsSummary topThreeFeelings={topThreeFeelings} />
                </div>
            </div>
            
            <GenerateImage 
                uid={uid} 
                saveImage={saveImage} 
                reflectionImagePath={reflectionImagePath}
                topThreeFeelings={topThreeFeelings}/>

            <BasicButton onClick={onKeepJournaling}>
                <div>Keep Journaling</div>
            </BasicButton>

        </div>
    </motion.div>
}

export const TopicsSummary = (
    {
        summary
    } : {
        summary: string
}) => {

    return <div>
        <div className="text-xl mb-2">
            Topics
        </div>
        <div className="text-sm">
            {
                summary?.split("\n").map((line, index) => 
                    <div key={index} className="ml-2">{line}</div>
                )
            }
        </div>
    </div>

}

export const FeelingsSummary = (
    {
        topThreeFeelings,
    } : {
        topThreeFeelings: Feeling[]
    }
) => {
    
    return <div>
        <div className="text-xl mb-2">
            Feelings
        </div>
        <div className="text-sm">
            {
                topThreeFeelings.map((feeling, index) => 
                    <div key={index} className="ml-2">{"- " + feeling.name + (feeling.percent ? " " + feeling.percent + "%" : "")}</div>
                )
            }
        </div>
    </div>
}
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useCallback, useContext, useEffect, useState } from "react";
import { db } from "../../firebase";
import type { RemirrorJSON } from '@remirror/core';
import { OnChangeJSON } from '@remirror/react';
import { TextEditor } from "./TextEditor";
import { useDebounce } from 'usehooks-ts';
import { AutosaveStatus, AutosaveStatusIndicator } from "./AutosaveStatus";
import { Entry } from "./Entry";
import { AuthContext } from "src/App";
import { Suggestion, SuggestionList, SuggestionType } from "../Suggestions/Suggestion";
import { SmartDropdown } from "./SmartDropdown/SmartDropdown";
import { Sidebar } from "./Sidebar/Sidebar";
import { FeelingsModuleData, SidebarModule, SidebarModuleType } from "./Sidebar/SidebarModule";
import { getCBTExercise } from "src/lib/getCBTExercise";
import proseMirrorToString from "src/lib/proseMirrorToString";
import { BackgroundGradient, GradientParams, defaultGradientParams } from "../Gradient/Gradient";
import { Wrapup } from "./Wrapup";
import { MoreVert } from "../Icons";
import { EntryMenu } from "./EntryMenu";
import { useNavigate } from "react-router-dom";

const EntryEditor2 = (
    {
        entry,
        initialSuggestion,
        selectedTheme,
    }: {
        entry: Entry,
        initialSuggestion?: Suggestion,
        selectedTheme?: string,
    }
) => {
    const { currentUser } = useContext(AuthContext);

    const [proseMirrorJSON, setProseMirrorJSON] = useState<RemirrorJSON>(entry.pmJSON);
    const longDebouncedProseMirrorJSON = useDebounce(proseMirrorJSON, 5_000);
    const [sidebarModules, setSidebarModules] = useState<SidebarModule[]>(entry.sidebarModules)
    const longDebouncedSidebarModules = useDebounce(sidebarModules, 5_000);
    
    // Autosave state
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [autosaveStatus, setAutosaveStatus] = useState<AutosaveStatus>(AutosaveStatus.UNSAVED);
    
    //dropdown 
    const [dropdownIndex, setDropdownIndex] = useState<number>(0);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState<Suggestion | undefined>();
    
    // Entry state
    const [selectedInitialSuggestion, setInitialSuggestion] = useState<Suggestion | undefined>(initialSuggestion);
    const [gradientParams, setGradientParams] = useState<GradientParams>(entry.gradientParams ? entry.gradientParams : defaultGradientParams);
    const [summary, setSummary] = useState<string>(entry.summary);
    const [wrappedUp, setWrappedUp] = useState<boolean>(entry.wrappedUp);
    const [theme, setTheme] = useState<string>(entry.theme ? entry.theme : (selectedTheme ? selectedTheme : "default")); // use db theme, then prop theme, then default theme

    // Ui state
    const [shortAnimateBackground, setShortAnimateBackground] = useState<number>(0);
    const [mobileSidebarExpanded, setMobileSidebarExpanded] = useState<boolean>(false);

    const navigate = useNavigate();

    const autosaveUpdateDoc = useCallback(
            (key: string, 
            value: RemirrorJSON | SidebarModule[] | GradientParams | string | boolean | SuggestionList[]) => {
        if (entry) {
            setAutosaveStatus(AutosaveStatus.SAVING);
            const entryRef = doc(db, "users", currentUser.uid, "entries", entry?.docId);
            updateDoc(entryRef, {
                [key]: value
            }).then(() => {
                setAutosaveStatus(AutosaveStatus.SAVED);
            })
        }
    }, [entry, currentUser])

    const saveImage = useCallback((full_path: string) => {
        const entryRef = doc(db, "users", currentUser.uid, "entries", entry?.docId);
        return updateDoc(entryRef, {
            ["reflectionImagePath"]: full_path
        });      
    }, [entry, currentUser]);

    // autosave the journal contents every 5 seconds
    useEffect(() => {
        if (longDebouncedProseMirrorJSON) {
            autosaveUpdateDoc("pmJSON", longDebouncedProseMirrorJSON);
        }
    }, [longDebouncedProseMirrorJSON, autosaveUpdateDoc])


    // autosave the feelings every 5 seconds
    useEffect(() => {
        if (longDebouncedSidebarModules) {
            autosaveUpdateDoc("sidebarModules", longDebouncedSidebarModules);
        }
    }, [longDebouncedSidebarModules, autosaveUpdateDoc])

    useEffect(() => {
        if (summary) {
            autosaveUpdateDoc("summary", summary);
        }
    }, [summary])

    useEffect(() => {
        autosaveUpdateDoc("gradientParams", gradientParams);
    }, [gradientParams, autosaveUpdateDoc])

    useEffect(() => {
        autosaveUpdateDoc("wrappedUp", wrappedUp);
    }, [wrappedUp])

    useEffect(() => {
        if (theme) {
            autosaveUpdateDoc("theme", theme);
        }
    }, [theme]);

    const onChange = (doc: RemirrorJSON) => {
        setProseMirrorJSON(doc);
        setAutosaveStatus(AutosaveStatus.UNSAVED);
    }

    const updateSidebarModules = (newSidebarModules: SidebarModule[]) => {
        setSidebarModules(newSidebarModules);
        setAutosaveStatus(AutosaveStatus.UNSAVED);
    }

    const addSuggestion = async (selectedDropdown: Suggestion) => {
        if (selectedDropdown.type === SuggestionType.CBT_WORKSHEET) {
            setIsLoading(true);
            setInitialSuggestion(undefined);
            try {
                selectedDropdown.exerciseJSON = await getCBTExercise(selectedDropdown.title, proseMirrorToString(proseMirrorJSON));
            } catch (error) {
                console.error("Failed to load CBT Exercise:", error);
            } finally {
                setIsLoading(false);
            }
        }
        setInitialSuggestion(undefined);
        setSelectedDropdownValue(selectedDropdown);
    };

    const saveSuggestions = (suggestions: SuggestionList[]) => {
        autosaveUpdateDoc("suggestions", suggestions);
    }

    const startWrapup = () => {
        setWrappedUp(true);
        setShortAnimateBackground(shortAnimateBackground => shortAnimateBackground + 1);
    }

    return <div>
        <BackgroundGradient
            shortAnimateCount={shortAnimateBackground}
            params={gradientParams}
            updateParams={setGradientParams}
            feelingsModuleData={sidebarModules &&
                sidebarModules.find((module) => module.type === SidebarModuleType.FEELINGS)?.data as FeelingsModuleData
            } />


        <div className="max-w-[900px] mx-auto min-h-[110vh] pt-12 text-tifla-green">

            <div className="w-full flex flex-col items-start justify-center text-left my-12 ">
                <div className="w-full px-4 rounded-lg overflow-hidden relative">
                    <div className="flex flex-row relative">
                        <div className="font-garamond text-4xl font-bold">{entry.date_created ? entry.date_created.toDateString() : undefined}</div>
                        <div className="ml-1 p-1">
                            <AutosaveStatusIndicator status={autosaveStatus} />
                        </div>
                        
                    </div>
                    
                    <div className={`relative flex flex-col sm:flex-row sm:space-x-12`}>
                        <div className={`w-full relative sm:w-3/4  ${mobileSidebarExpanded ? "opacity-0" : ""} transition-all duration-500`}>
                            <EntryMenu deleteEntry={() => {
                                deleteDoc(entry.ref)
                                    .then(() => {
                                        navigate("/home");
                                    })
                                    .catch((error) => {
                                        console.log("failed to delete", error);
                                    }); 
                            }} />

                            <TextEditor
                                selectedDropdownValue={selectedInitialSuggestion ? selectedInitialSuggestion : selectedDropdownValue}
                                isLoading={isLoading}
                                placeholder='Enter text...'
                                initialContent={proseMirrorJSON}
                                setDropdownIndex={setDropdownIndex}
                                autoFocus>
                                <OnChangeJSON onChange={onChange} />
                            </TextEditor>
                            
                            <SmartDropdown
                                onSuggestionSelected={addSuggestion}
                                initialSuggestion={initialSuggestion}
                                journalPMJSON={proseMirrorJSON}
                                selectedIndex={dropdownIndex}
                                onWrapup={startWrapup}
                                hide={wrappedUp}
                                saveSuggestions={saveSuggestions}
                                theme={theme} />

                            <Wrapup 
                                uid={currentUser.uid}
                                show={wrappedUp} 
                                currentSummary={summary} 
                                journalPMJSON={proseMirrorJSON}
                                updateSummary={setSummary}
                                feelingsModuleData={sidebarModules &&
                                    sidebarModules.find((module) => module.type === SidebarModuleType.FEELINGS)?.data as FeelingsModuleData}
                                onKeepJournaling={() => {setWrappedUp(false)}}
                                saveImage={saveImage}
                                reflectionImagePath={entry.reflectionImagePath}
                                />
                                
                        </div>
                        <div className={`absolute sm:relative ${mobileSidebarExpanded ?  "left-0" : "left-[110%] sm:left-0"} w-full sm:w-1/3 pt-[20px] transition-all duration-500`}>
                            <Sidebar 
                                sidebarModules={sidebarModules} 
                                onSidebarModulesChanged={updateSidebarModules} 
                                journalPMJSON={proseMirrorJSON}
                                mobileExpanded={mobileSidebarExpanded} 
                                toggleExpanded={() => {setMobileSidebarExpanded(mobileSidebarExpanded => !mobileSidebarExpanded)}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

}


export { EntryEditor2 }
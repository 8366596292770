import { useEffect, useRef } from "react";
import { FeelingsModuleData } from "../EntryEditor/Sidebar/SidebarModule";
import { GradientParams, defaultGradientParams, extractColorsFromFeelingsData } from "./Gradient";
import { pickGradientColors, randomLightPalette } from "./Palette";
import { Sketch } from "./Sketch";

export const GradientThumb = ({
    params,
    feelingsModuleData,
}: {
    params?: GradientParams,
    feelingsModuleData?: FeelingsModuleData | undefined,
}) => {

    const ref = useRef(null);

    useEffect(()  => {
        if (ref.current) {
            if (feelingsModuleData && feelingsModuleData.feelings && feelingsModuleData.feelings.length > 0) {
                const allColors = extractColorsFromFeelingsData(feelingsModuleData);
                const palette = pickGradientColors(allColors);
                const sketch = new Sketch(ref.current, params ? params : defaultGradientParams(), () => {}, palette)    
            }
            else {
                const palette = pickGradientColors(randomLightPalette());
                new Sketch(ref.current,  defaultGradientParams(), () => {}, palette)
            }
            
        }
    }, [])

    return <div className="relative">
        <div className="absolute w-full h-[500px] opacity-20 -z-30 blur-[0px]" ref={ref} />
    </div>
}
import { Feeling } from "../components/EntryEditor/Sidebar/FeelingsModule/Feeling"
import { ChatCompletionRequestMessageRoleEnum, CreateChatCompletionResponse } from "openai"
import { httpsCallable } from "firebase/functions"
import { functions } from "../firebase"

const FEELINGS_PROMPT = `identify up to two single-word emotions in the following text, and a hex color for each one, in a bulleted list in the following format: - [emotion] : [#FFFFFF] \n`

const formatFeeling = (name: string, color: string): Feeling => {
    return {
        name: name,
        percent: null,
        color: color
    } as Feeling
}

function capitalizeFirstLetter(string:string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const trimFeelingName = (feelingName: string): string => {
    return capitalizeFirstLetter(feelingName.replace(/[^A-Za-z0-9]/g, "").trim());
}

const parseRow = (row: string): Feeling => {
    const halves = row.split(':');
    const feelingName = trimFeelingName(halves[0]);
    const color = halves[1].trim().replaceAll("[", "").replaceAll("]", "").slice(0, 7);
    return formatFeeling(feelingName, color);
}

const parseResponse = (response: CreateChatCompletionResponse) : Feeling[] => {
    const messageText = response.choices[0].message?.content;
    if (!messageText) return [];
    const newFeelings = messageText.split("\n").map((row) => parseRow(row));
    return newFeelings;
}

const getGPTFeelings = (text: string, callback: (feelings: Feeling[]) => void) => {
    
    const messages = [];
    const prompt = FEELINGS_PROMPT + text;

    messages.push(
        {
            role: ChatCompletionRequestMessageRoleEnum.System, 
            content: prompt}
    );

    const openAi = httpsCallable(functions, "openAiCompletion");

    openAi({messages: messages, model: "gpt-3.5-turbo"}).then((result) => {
        /** @type {any} */
        const data: any = result.data;
        
        const apiResponse = data.openApiResponseData as CreateChatCompletionResponse;
        
        if (apiResponse) {
            const newFeelings = parseResponse(apiResponse);
            callback(newFeelings);
        }
    }).catch((error) => {
        console.error(error);
    });
}

export { getGPTFeelings, capitalizeFirstLetter }
import React, { useState } from 'react';
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const onGoogleLogin = () => {
        //signInWithRedirect(auth, new GoogleAuthProvider());
        signInWithPopup(auth, new GoogleAuthProvider()).then((result) => {
            navigate("/home");
        }).catch((error) => {
            console.log(error);
        });
    }

    const onEmailLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                navigate("/home");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    };
    

    return (
        <div className="container mx-auto flex flex-col items-center">
            <div className="shadow-lg w-[300px] mt-48 p-4 rounded-lg bg-white">
                <form className="flex flex-col items-start space-y-4" onSubmit={onEmailLogin}>
                    <div className="w-full">
                        <label htmlFor="email-address">Email address</label>
                        <input
                            className="border border-gray-400 w-full p-2 rounded-md text-black"
                            id="email-address"
                            name="email"
                            type="email"
                            required
                            placeholder="Email address"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="password">Password</label>
                        <input
                            className="border border-gray-400 w-full p-2 rounded-md text-black"
                            id="password"
                            name="password"
                            type="password"
                            required
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <button className="w-full bg-neutral-200 hover:bg-neutral-300 font-bold" type="submit">
                        Login
                    </button>
                    <button
                        className="w-full bg-neutral-200 font-bold hover:bg-neutral-300"
                        onClick={onGoogleLogin}>

                        Login with Google
                    </button>
                    <div className="text-sm w-full text-center">
                    No account yet?{' '}
                        <span className="underline cursor-pointer" onClick={() => { navigate('/signup') }}>
                            Sign up
                        </span>
                    </div>
                </form>

                
            </div>
        </div>
    );
};

export { Login };

import { Entry } from "./EntryEditor/Entry"
import { useState } from "react"
import { useEffect } from "react"
import { Suggestion } from "./Suggestions/Suggestion"
import { SuggestionType } from "./Suggestions/Suggestion"
import { useMultipleEntryReflection } from "src/lib/getSmartCardQuestons"
import { useEntries } from "src/lib/useEntries"
import getLastSevenDaysEntries from "src/lib/getEntriesFromLastWeek"
import { topFeelings } from "./EntryEditor/Sidebar/FeelingsModule/FeelingsModule"
import { findFeelingsModuleData } from "./EntryEditor/Sidebar/FeelingsModule/FeelingsModule"
import { aggregateTopFeelings } from "./EntryEditor/Sidebar/FeelingsModule/FeelingsModule"
import { Feeling } from "./EntryEditor/Sidebar/FeelingsModule/Feeling"

export const WeeklyBox = ({
  onSelect,
  uid,
}: {
  onSelect: (suggestion: Suggestion, theme: string) => void;
  uid: string;
}) => {
  const entries = useEntries(uid);
  const entriesFromLastWeek = getLastSevenDaysEntries(entries);
  entriesFromLastWeek.map((entryItem, index) => (
      console.log(entryItem.date_created + entryItem.summary)
  ))

  const { resultText: multiplePastReflection, loading } = useMultipleEntryReflection(
    uid,
    SuggestionType.MULTIPLE_PAST_REFLECTION,
    entries.length > 5 
  );

  const useSuggestions = (): Suggestion[] => {
    let suggestions: Suggestion[] = [];
    suggestions.push({
        type: SuggestionType.MULTIPLE_PAST_REFLECTION,
        title: "Weekly Summary",
        // intention: multiplePastReflection,
        cardPrompt: "",
        description: multiplePastReflection,
      });    
    return suggestions;
  };

  return (
    <div className="w-full h-auto">
      {useSuggestions().map((suggestion, index) => (
        <PersonalizedCard
          key={index}
          suggestion={suggestion}
          entry={entriesFromLastWeek}
          loading={loading}
          onClick={() => {
            onSelect(suggestion, suggestion.theme ? suggestion.theme : "default");
          }}
        />
      ))}
    </div>
  );
};

export const PersonalizedCard = ({
    suggestion,
    entry,
    onClick,
    loading
  }: {
    suggestion: Suggestion;
    entry: Entry[] | null;
    onClick: () => void;
    loading:boolean;
  }) => {
    const allFeelings: Feeling[] = [];
  
    entry?.map((entryItem) => {
      const feelingsModuleData = findFeelingsModuleData(entryItem.sidebarModules);
      const topThreeFeelings = topFeelings(feelingsModuleData);
      allFeelings.push(...topThreeFeelings);
    });
  
    const overallTopFiveFeelings = aggregateTopFeelings(allFeelings);

    const [buttonVisible, setButtonVisible] = useState(false);

        useEffect(() => {
            if (!loading) {
              setButtonVisible(true);
            }
          }, [loading]);

    const entryLength = entry ? entry.length : 0;
  
    return (
      <div
        key={suggestion.title}
        className="w-full lg:w-full sm:w-1/2 h-48 pl-4 py-2 cursor-pointer hover:scale-105 transition-all duration-500 text-tifla-green"
        onClick={onClick}
      >
        <div className="rounded-lg overflow-hidden shadow-md  bg-white flex flex-col justify-between">
          <div className="p-6">
            <div className="w-full text-3xl font-garamond mb-2">
              {suggestion.title}
            </div>
            {entry && (
            <p className="mx-2 text-sm">
              {" "}
              You wrote <b>{entryLength}</b> days in the last week{" "}
            </p>
          )}
{overallTopFiveFeelings.length!==0 ? (
          <div className="w-full text-sm">
            <p className="mx-2 my-2">
              {" "}
              Your top emotions for the past week are:{" "}
              <b>
                {overallTopFiveFeelings &&
                  overallTopFiveFeelings.map((feeling, index) => (
                    <div className="mx-2 p-1" key={index}>
                      <h2>{feeling.name}</h2>
                    </div>
                  ))}
              </b>
            </p>
            <div className="w-full text-sm p-6">
          {buttonVisible && <button>Create Reflection Prompts</button>}
          </div>
          </div>
          ):(
            <div className="w-full text-sm mx-2 my-2">Keep using Amble to help us summarize your weekly emotions and draw insightful patterns over time.</div>
          )}
          </div>
        </div>
      </div>
    );
  };
import { getSingleChatCompletion } from "./TiflaGPTApi";

export const getCBTExercise = async (chatCompletion: string, inputText: string): Promise<string | undefined> => {
    const prompt = `Based on the following Cognitive Behavioural Therapy exercise defined here : 
    "${chatCompletion}", and the original journal input: "${inputText}", 
    please return a simple CBT worksheet with html tags and insert in the 
    content section of the following JSON object. 
    Each step of the exercise is a new paragraph
    that contains the step number as "Step X" with a <b> Bold tag,  
    the step heading <b> Bold tag, and
    an example starting with "e.g.: " for each step 
    from the journal entry in <i> Italic tag. 
    Try to limit every step to ~150 characters. 
    Include the title of the exercise in the content with a <b> Bold tag.
    Do not provide any extra text. 
  " : 
    {
       content: "INSERT_CONTENT_HERE"
      }`;

    const response = await getSingleChatCompletion(prompt);
    return response;
}
import { CreateChatCompletionResponse } from 'openai';
import { useEffect, useState } from 'react';
import { useEntries } from "../lib/useEntries";
import { SuggestionType, SUGGESTION_TYPE_TO_PROMPT } from 'src/components/Suggestions/Suggestion';
import { ChatCompletionRequestMessageRoleEnum } from "openai"
import { httpsCallable } from "firebase/functions"
import { functions } from "../firebase"  
import proseMirrorToString from './proseMirrorToString';
import { format, isYesterday } from 'date-fns';
import getLastSevenDaysEntries from "src/lib/getEntriesFromLastWeek"

const useMultipleEntryReflection = (uid: string, suggestionType: SuggestionType, reflectionCondition: boolean ): { resultText: string, loading: boolean } => {
  const SMART_CARD_PROMPT = SUGGESTION_TYPE_TO_PROMPT[suggestionType];
  let concatenatedText = '';
  const entries = useEntries(uid);

  const [resultText, setResultText] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!reflectionCondition) {
      setResultText('✨ Amble needs to learn more about you to draw insights. Keep writing!');
      setLoading(false);
      return;
    }

    let lastWeekEntryText: any[] = [];

    if (suggestionType === SuggestionType.MULTIPLE_PAST_REFLECTION) {
     const entriesFromLastWeek = getLastSevenDaysEntries(entries);
    lastWeekEntryText= entriesFromLastWeek.slice(0, 7).map((entry) => proseMirrorToString(entry.pmJSON));
    } else if (suggestionType === SuggestionType.YESTERDAY_REFLECTION) {
      const yesterdayEntries = entries.filter((entry) => isYesterday(entry.date_created));
      lastWeekEntryText = yesterdayEntries.slice(0, 5).map((entry) => proseMirrorToString(entry.pmJSON));
    }
    concatenatedText = lastWeekEntryText.join(' ');

    const fetchData = async () => {
      const messages = [
        {
          role: ChatCompletionRequestMessageRoleEnum.System,
          content: SMART_CARD_PROMPT + concatenatedText,
        },
      ];

      const openAi = httpsCallable(functions, "openAiCompletion");
      try {
        const result = await openAi({ messages, model: "gpt-3.5-turbo" });
        const data: any = result.data;
        const apiResponse = data.openApiResponseData as CreateChatCompletionResponse;

        if (apiResponse) {
          const messageText = apiResponse.choices[0].message?.content;
          setResultText(messageText || '');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [reflectionCondition, uid, suggestionType]);

  if (loading) {
    // Return a loading state if needed
  }

  return {resultText, loading};
};

export { useMultipleEntryReflection };


// const useMultipleEntryReflection = (uid: string, SuggestionType: SuggestionType, reflectionCondition: Boolean ): string => {
// const SMART_CARD_PROMPT = SUGGESTION_TYPE_TO_PROMPT[SuggestionType];
// let concatenatedText=''
// const entries = useEntries(uid);

// if (SUGGESTION_TYPE_TO_PROMPT[SuggestionType]=== "MULTIPLE_PAST_REFLECTION") {
//     const lastFiveEntryText = entries.slice(0, 5).map((entry) => proseMirrorToString(entry.pmJSON));
//     concatenatedText = lastFiveEntryText.join(' ');
//     console.log("past five entries are" + concatenatedText);

// } else {
//     const yesterdayEntries = entries.filter((entry) =>
//     isYesterday(entry.date_created)
//   );
//     const lastFiveEntryTextYesterday = yesterdayEntries.slice(0, 5).map((entry) => proseMirrorToString(entry.pmJSON));
//     concatenatedText = lastFiveEntryTextYesterday.join(' ');
//     console.log("past five entries from yesterday are" + concatenatedText);
// }

//   const [resultText, setResultText] = useState('');
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       const messages = [
//         {
//           role: ChatCompletionRequestMessageRoleEnum.System,
//           content: SMART_CARD_PROMPT + concatenatedText,
//         },
//       ];

//       const openAi = httpsCallable(functions, "openAiCompletion");
//       try {
//         const result = await openAi({ messages, model: "gpt-3.5-turbo" });
//         const data: any = result.data;
//         const apiResponse = data.openApiResponseData as CreateChatCompletionResponse;

//         if (apiResponse) {
//           const messageText = apiResponse.choices[0].message?.content;
//           console.log("response is" + messageText)
//           setResultText(messageText || '');
//         }
//       } catch (error) {
//         console.error(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [concatenatedText]);

//   if (loading) {
//     // Return a loading state if needed
//   }

//   return resultText;
// };

// export { useMultipleEntryReflection };

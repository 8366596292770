import { useState } from 'react';
import { GoogleAuthProvider, createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase';
import { createUser } from '../../lib/createUser';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const onSubmit = async (e: any) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                createUser(user.uid)
                navigate('/home');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    }

    const signUpWithGoogle = () => {
        const googleAuthProvider = new GoogleAuthProvider();
        googleAuthProvider.setCustomParameters({
            prompt: 'select_account',
        });
        signInWithPopup(auth, googleAuthProvider).then((result) => {
            navigate("/home");   
        }).catch((error) => {
            
        });
    };

    return (
        <div className="container mx-auto flex flex-col items-center">
            <div className="shadow-lg w-[300px] mt-48 p-4 rounded-lg bg-white">
                <form className="flex flex-col items-start space-y-4" onClick={onSubmit} >
                    <div className="w-full">
                        <label htmlFor="email-address">Email address</label>
                        <input
                            className="border border-gray-400 w-full p-2 rounded-md text-black"
                            id="email-address"
                            name="email"
                            type="email"
                            required
                            placeholder="Email address"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="password">Password</label>
                        <input
                            className="border border-gray-400 w-full p-2 rounded-md text-black"
                            id="password"
                            name="password"
                            type="password"
                            required
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button className="w-full bg-neutral-200 hover:bg-neutral-300" type="submit">Sign up</button>
                    <button 
                        className="w-full bg-neutral-200 hover:bg-neutral-300"
                        onClick={signUpWithGoogle}>Sign up with Google</button>
                    
                    <div className="text-sm w-full text-center">
                    Already have an account?{' '}
                        <span
                            className="underline cursor-pointer text-center"
                            onClick={() => { navigate('/login') }}>
                            
                            Sign in
                        </span>
                    </div>
                </form>


            </div>
        </div>
    )
}

export { Signup }
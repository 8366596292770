import { Entry } from "src/components/EntryEditor/Entry";
import { isWithinInterval, endOfDay, subDays } from 'date-fns';

const getLastSevenDaysEntries = (entries: Entry[]): Entry[] => {
  const now = new Date();
  const sevenDaysAgo = subDays(now, 7);

  const latestEntries: Entry[] = entries.reduce((acc: Entry[], entry: Entry) => {
    const entryDate = new Date(entry.date_created);

    if (isWithinInterval(entryDate, { start: sevenDaysAgo, end: now })) {
      const existingEntry = acc.find((accEntry) =>
        new Date(accEntry.date_created).toLocaleDateString() === entryDate.toLocaleDateString()
      );

      if (!existingEntry || entryDate > new Date(existingEntry.date_created)) {
        return [...acc.filter((accEntry) => new Date(accEntry.date_created).toLocaleDateString() !== entryDate.toLocaleDateString()), entry];
      }
    }

    return acc;
  }, []);

  return latestEntries;
};

export default getLastSevenDaysEntries;



export const getGreeting = (user: string) => {
    const currentTime = new Date();
    const hour = currentTime.getHours();
    let timeCategory = '';
    if (hour < 12) {
        timeCategory = 'morning';
    }
    else if (hour < 18) {
        timeCategory = 'afternoon';
    }
    else {
        timeCategory = 'evening';
    }
    
    
    return `It's ${currentTime.toLocaleString(window.navigator.language, {weekday: 'long'})} ${timeCategory},`;
}
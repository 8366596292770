import { useCallback, useEffect, useRef, useState } from "react";
import { pickGradientColors, randomLightPalette } from "./Palette";
import { defaultGradientParams } from "./Gradient";
import { Sketch } from "./Sketch";

export const ScrollingBackgroundGradient = () => {
    const ref = useRef(null);
    const [sketch, setSketch] = useState<Sketch | null>(null);
    const [lastScroll, setLastScroll] = useState(0);

    const handleScroll = useCallback((event: any) => {
        const delta = window.scrollY - lastScroll;
        setLastScroll(window.scrollY);

        if (sketch) {
            sketch.scroll(Math.abs(delta * .01));
        }
    }, [lastScroll]);

    useEffect(() => {
        setLastScroll(window.scrollY);
        if (sketch) {
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll)
            }
        }
    }, [sketch, handleScroll])
    
    useEffect(()  => {
        if (ref.current) {
            const palette = pickGradientColors(randomLightPalette());
            setSketch(new Sketch(ref.current,  defaultGradientParams(), () => {}, palette));
        }
    }, [])

    return <div className="relative">
        <div className="fixed w-full h-[100vh] opacity-20 -z-30 blur-[0px]" ref={ref} />
    </div>
}
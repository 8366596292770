import { useState } from "react";
import { MoreVert } from "../Icons"

export const EntryMenu = (
    {
        deleteEntry,
    } : {
        deleteEntry: () => void
    }
) => {
    
    const [open, setOpen] = useState(false);

    
    return <div 
            className="absolute right-0 -top-10  "
            onClick={() => {setOpen(open => !open)}}>
        <div className="p-1 rounded-full hover:bg-black/20 cursor-pointer transition-all duration-500">{MoreVert}</div>
        <div className={`absolute right-0 top-10 flex flex-col p-2 text-xs bg-white rounded-lg ${open ? "" : "hidden"}`}>
            <div className="w-[100px] hover:bg-black/10 transition-all duration-500 p-2 cursor-pointer rounded-md"
                onClick={deleteEntry}>
                Delete Entry
            </div>
        </div>
    </div>
}


const ExpandMoreIcon = (
    {
        fill
    } : {
        fill?: string
    }
) => {
    return <div className={`${ fill ? `fill-${fill}` : ""}`}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 711 240 471l56-56 184 184 184-184 56 56-240 240Z"/></svg>
    </div>
    
}

const ProfileIcon = <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 96 960 960" width="40"><path d="M226 794q59-42.333 121.333-65.5Q409.667 705.334 480 705.334T613 728.5q62.667 23.167 121.667 65.5 41-49.667 59.833-103.667 18.834-54 18.834-114.333 0-141-96.167-237.167T480 242.666q-141 0-237.167 96.167T146.666 576q0 60.333 19.167 114.333T226 794Zm253.876-184.667q-58.209 0-98.043-39.957Q342 529.419 342 471.209q0-58.209 39.957-98.042 39.958-39.834 98.167-39.834t98.043 39.958Q618 413.248 618 471.457q0 58.21-39.957 98.043-39.958 39.833-98.167 39.833ZM480.312 976q-82.645 0-155.645-31.5-73-31.5-127.334-85.833Q143 804.333 111.5 731.489T80 575.823q0-82.823 31.5-155.49 31.5-72.666 85.833-127Q251.667 239 324.511 207.5T480.177 176q82.823 0 155.49 31.5 72.666 31.5 127 85.833Q817 347.667 848.5 420.355T880 575.688q0 82.645-31.5 155.645-31.5 73-85.833 127.334Q708.333 913 635.645 944.5T480.312 976ZM480 909.334q54.333 0 105-15.834 50.667-15.833 97.667-52.167-47-33.666-98-51.5Q533.667 772 480 772t-104.667 17.833q-51 17.834-98 51.5 47 36.334 97.667 52.167 50.667 15.834 105 15.834Zm0-366.667q31.333 0 51.334-20 20-20 20-51.334 0-31.333-20-51.333-20.001-20-51.334-20-31.333 0-51.334 20-20 20-20 51.333 0 31.334 20 51.334 20.001 20 51.334 20Zm0-71.334Zm0 369.334Z"/></svg>
const EditIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="24"><path d="M200 856h56l345-345-56-56-345 345v56Zm572-403L602 285l56-56q23-23 56.5-23t56.5 23l56 56q23 23 24 55.5T829 396l-57 57Zm-58 59L290 936H120V766l424-424 170 170Zm-141-29-28-28 56 56-28-28Z"/></svg>
const GridIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="24"><path d="M120 536V216h320v320H120Zm0 400V616h320v320H120Zm400-400V216h320v320H520Zm0 400V616h320v320H520ZM200 456h160V296H200v160Zm400 0h160V296H600v160Zm0 400h160V696H600v160Zm-400 0h160V696H200v160Zm400-400Zm0 240Zm-240 0Zm0-240Z"/></svg>
const LogoutIcon= <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" fill="white" /></svg>

const BackIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 904.479 151.521 576 480 247.521l75.653 74.653-200.825 200.825h453.651v106.002H354.828l200.825 200.825L480 904.479Z"/></svg>
const CloudSaved = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="m429 742 204-204-51-51-153 153-68-68-51 51 119 119ZM240 864q-80 0-136-56T48 672q0-76 52-131.5T227 480q23-85 92.5-138.5T480 288q103 0 179 69.5T744 528q70 0 119 49t49 119q0 70-49 119t-119 49H240Zm0-72h504q40 0 68-28t28-68q0-40-28-68t-68-28h-66l-6-65q-7-74-62-124.5T480 360q-64 0-114.5 38.5T297 500l-14 49-51 3q-48 3-80 37.5T120 672q0 50 35 85t85 35Zm240-216Z"/></svg>
const CloudUnsaved = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M768 966 666 864H240q-80.291 0-136.145-55.855Q48 752.291 48 672q0-76 52-131t127-60q3.333-10.783 6.667-20.891Q237 450 242 440L90 288l51-51 678 678-51 51ZM240 792h354L298 496q-4 13-7.5 26.5T283 549l-51 4q-48 4-80 37.679-32 33.678-32 81.321 0 49.714 35.143 84.857T240 792Zm206-148Zm394 190-53-52q23-12 38-34.817 15-22.816 15-51.183 0-40.32-27.84-68.16Q784.32 600 744 600h-66l-6-65q-7-74-62-124.5T479.781 360q-24.469 0-47.125 6T389 383l-52-52q32-20 67.646-31.5Q440.293 288 480 288q103 0 179 69.5T744 528q70 0 119 49t49 119q0 43-19.5 79T840 834ZM588 582Z"/></svg>
const CloudSaving = <svg viewBox="0 96 960 960" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M 240 864 C 186.667 864 141.333 845.333 104 808 C 66.667 770.667 48 725.333 48 672 C 48 621.333 65.333 577.5 100 540.5 C 134.667 503.5 177 483.333 227 480 C 242.333 423.333 273.167 377.167 319.5 341.5 C 365.833 305.833 419.333 288 480 288 C 548.667 288 608.333 311.167 659 357.5 C 709.667 403.833 738 460.667 744 528 C 790.667 528 830.333 544.333 863 577 C 895.667 609.667 912 649.333 912 696 C 912 742.667 895.667 782.333 863 815 C 830.333 847.667 790.667 864 744 864 L 240 864 Z M 240 792 L 744 792 C 770.667 792 793.333 782.667 812 764 C 830.667 745.333 840 722.667 840 696 C 840 669.333 830.667 646.667 812 628 C 793.333 609.333 770.667 600 744 600 L 678 600 L 672 535 C 667.333 485.667 646.667 444.167 610 410.5 C 573.333 376.833 530 360 480 360 C 437.333 360 399.167 372.833 365.5 398.5 C 331.833 424.167 309 458 297 500 L 283 549 L 232 552 C 200 554 173.333 566.5 152 589.5 C 130.667 612.5 120 640 120 672 C 120 705.333 131.667 733.667 155 757 C 178.333 780.333 206.667 792 240 792 Z M 480 576 Z"/>
        <svg x="330" y="450" width="300" height="300" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path style={{strokeWidth: "4.5px"}} d="M 11.974 19.725 C 17.751 19.725 21.361 13.472 18.473 8.469 C 15.585 3.466 8.364 3.466 5.476 8.469 C 4.975 9.337 4.652 10.297 4.528 11.291" className="spinner_6kVp" /></svg>
    </svg>

const CloseIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="m291 816-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"/></svg>
const TrashIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M312 912q-29.7 0-50.85-21.15Q240 869.7 240 840V360h-48v-72h192v-48h192v48h192v72h-48v479.566Q720 870 698.85 891 677.7 912 648 912H312Zm336-552H312v480h336V360ZM384 768h72V432h-72v336Zm120 0h72V432h-72v336ZM312 360v480-480Z"/></svg>
const AddIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M444 816V612H240v-72h204V336h72v204h204v72H516v204h-72Z"/></svg>
const PromptIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-80v-172q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l52 205q5 19-7 34.5T840-360h-80v120q0 33-23.5 56.5T680-160h-80v80h-80v-160h160v-200h108l-38-155q-23-91-98-148t-172-57q-116 0-198 81t-82 197q0 60 24.5 114t69.5 96l26 24v208h-80Zm254-360Zm-14 120q17 0 28.5-11.5T520-360q0-17-11.5-28.5T480-400q-17 0-28.5 11.5T440-360q0 17 11.5 28.5T480-320Zm-30-128h61q0-25 6.5-40.5T544-526q18-20 35-40.5t17-53.5q0-42-32.5-71T483-720q-40 0-72.5 23T365-637l55 23q7-22 24.5-35.5T483-663q22 0 36.5 12t14.5 31q0 21-12.5 37.5T492-549q-20 21-31 42t-11 59Z"/></svg>

export const RightArrow = <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15"><path d="m288-96-68-68 316-316-316-316 68-68 384 384L288-96Z"/></svg>
export const LeftArrow = <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15"><path d="M384-96 0-480l384-384 68 68-316 316 316 316-68 68Z"/></svg>
export const ContinueArrow = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m578.462-225.384-28.308-28.308L756.461-460H111.538v-40h644.923L550.154-706.308l28.308-28.308L833.077-480 578.462-225.384Z"/></svg>

export const CircleCheck = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M382-200 113-469l97-97 172 173 369-369 97 96-466 466Z"/></svg>
export const AddImage = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M204.616-140.001q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.067v-535.382q0-29.827 21.24-51.067 21.24-21.241 51.067-21.241h347.691V-760H204.616q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v535.382q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462h535.383q5.385 0 8.846-3.462 3.462-3.462 3.462-8.847V-560h59.999v347.691q0 29.827-21.241 51.067-21.24 21.241-51.066 21.241H204.616Zm483.077-475.385v-80h-80v-59.998h80v-80h59.998v80h80v59.998h-80v80h-59.998ZM262.309-290.001h423.073L553.845-465.384 441.538-319.231l-79.999-102.306-99.23 131.536ZM192.307-760v560V-760Z"/></svg>
export const Refresh = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M483.077-200q-117.25 0-198.625-81.339-81.375-81.34-81.375-198.539 0-117.199 81.375-198.661Q365.827-760 483.077-760q71.308 0 133.538 33.884 62.231 33.885 100.308 94.577V-760h40v209.231H547.692v-40h148q-31.231-59.846-87.884-94.539Q551.154-720 483.077-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h42.462Q725.077-310.154 651-255.077T483.077-200Z"/></svg>
export const Pencil = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M206.152-203.761h55.174l333.609-333.848-55.174-55.173-333.609 333.847v55.174ZM777.957-595.37 597.761-775.804l42.304-42.544q28.348-28.587 67.152-28.706 38.805-.12 67.392 28.228l51.63 51.63q25.913 25.435 25.196 62.25-.718 36.816-25.674 61.772l-47.804 47.804Zm-58.761 58.761L298.761-116.174H118.326V-296.37l420.435-420.434 180.435 180.195Zm-151.109-28.087-28.326-28.086 55.174 55.173-26.848-27.087Z"/></svg>
export const MenuIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M130.001-254.616v-59.999h699.998v59.999H130.001Zm0-195.385v-59.998h699.998v59.998H130.001Zm0-195.384v-59.999h699.998v59.999H130.001Z"/></svg>
export const MoreVert = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-189.233q-24.749 0-42.374-17.624-17.625-17.625-17.625-42.374 0-24.75 17.625-42.374Q455.251-309.23 480-309.23q24.749 0 42.374 17.625 17.625 17.624 17.625 42.374 0 24.749-17.625 42.374-17.625 17.624-42.374 17.624Zm0-230.768q-24.749 0-42.374-17.625-17.625-17.625-17.625-42.374 0-24.749 17.625-42.374 17.625-17.625 42.374-17.625 24.749 0 42.374 17.625 17.625 17.625 17.625 42.374 0 24.749-17.625 42.374-17.625 17.625-42.374 17.625Zm0-230.769q-24.749 0-42.374-17.625-17.625-17.624-17.625-42.374 0-24.749 17.625-42.374 17.625-17.624 42.374-17.624 24.749 0 42.374 17.624 17.625 17.625 17.625 42.374 0 24.75-17.625 42.374Q504.749-650.77 480-650.77Z"/></svg>

export { ExpandMoreIcon, ProfileIcon, EditIcon, GridIcon, LogoutIcon, BackIcon, CloudUnsaved, CloudSaved, CloudSaving, CloseIcon, TrashIcon, AddIcon, PromptIcon}
import { httpsCallable } from "firebase/functions";
import { ChatCompletionRequestMessageRoleEnum, CreateChatCompletionResponse } from "openai";
import { functions } from "src/firebase";

const getSingleChatCompletion = async (prompt: string) => {
    
    const openAi = httpsCallable(functions, "openAiCompletion");

    const messages = [{
        role: ChatCompletionRequestMessageRoleEnum.System, 
        content: prompt}];

    try {
        const result = await openAi({messages: messages, model: "gpt-3.5-turbo"});
        /** @type {any} */
        const data: any = result.data;
        const apiResponse = data.openApiResponseData as CreateChatCompletionResponse;
        const messageText = apiResponse.choices[0].message?.content;
        
        if (messageText) {
            return messageText;
        }
    }
    catch (error) {
        throw error;
    }
}

const getImage = async (prompt: string, n?: number) => {
    const openAi = httpsCallable(functions, "openAiImage");

    try {
        const result = await openAi(
            {
                prompt: prompt, 
                n: n ? n: 3,
                size: "512x512"});
        /** @type {any} */
        const data: any = result.data;
        const apiResponse = data.openApiResponseData;
        const images = apiResponse.data;
        
        if (images) {
            return images;
        }
    }
    catch (error) {
        throw error;
    }

}

export { getSingleChatCompletion, getImage}
import { useState } from "react";



const LandingFAQ = () => {

  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  return <section className="py-10 sm:py-16 font-garamond text-lg">
    <div className="max-w-2xl mx-auto text-center w-full">
      <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">FAQ</h2>
      <p className="max-w-xl text-lg mx-auto mt-4 text-base leading-relaxed text-gray-600">Commonly asked questions, for any inquires please contact us at amblejournal.ai@gmail.com</p>
    </div>
    <div className="w-full text-center">
      {getAllFaq().map((faq, index) => <FAQCard key={index} faq={faq} />)}
    </div>

  </section>
}

export type FAQ = {
  question: string,
  answer: string
}

const getAllFaq = (): FAQ[] => {
  let faqs: FAQ[] = [];

  faqs.push({
    question: "What is the purpose of an AI assisted journaling app?",
    answer: "This app aims to support individuals in achieving a healthier mental state by promoting mindful practices and helping users process emotions through guided journaling. It uses AI technology, (specifically models developed by OpenAI) to guide users through scenarios that allow for better emotional management and understanding. Prompts can range from follow-up question to help get over writer’s block, Cognitive Behavrioal exercises to help self-triage, and even visual expression of emotions via colours and images."
  });
  faqs.push({
    question: "Can you read my journal?",
    answer: "No, we cannot. Privacy is our utmost priority. All interactions within the app are confidential, encrypted and secure (even for us)."
  });
  faqs.push({
    question: "Do AI companies get my data?",
    answer: "We only send anonymised data to OpenAI models for generating and fine tuning prompts on a user’s journal entry. According to Open AI’s data policy as of March 1st 2023, OpenAI will not use data submitted by customers via our API to train OpenAI models or improve OpenAI’s service offering. You can read more about their policy here."
  });
  faqs.push({
    question: "Do you have a mobile app?",
    answer: "Not yet! Our experience is mobile-friendly using any web browser. Stay tuned for an announcement for our mobile app."
  });
  faqs.push({
    question: "Do I need to be a journaler to use Amble?",
    answer: "No, you do not! Our hope it this app can help you develop a journaling practice that helps you not only document your days but live them more mindfully."
  });
  faqs.push({
    question: "Who built Amble?",
    answer: "Amble is the brainchild of a team of loving technologists, mindfulness facilitators & artists from the world's best tech organizations like Google, Uber, South Park Commons."
  });
  return faqs;
}

export const FAQCard: React.FC<{ faq: FAQ }> = ({ faq }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }
  return (
    <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-8">
      <div
        className={`transition-all duration-200 bg-gray-50 rounded-md border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 ${expanded ? 'bg-gray-50' : ''
          }`}
      >
        <button type="button" className="flex items-center justify-between w-full px-4 py-5 sm:p-6" onClick={toggleExpanded}>
          <span className="flex text-lg font-semibold text-black">{faq.question}</span>

          <svg className={`w-6 h-6 text-gray-400 ${expanded ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>

        {expanded && (
          <div className="p-4 pb-5 sm:px-6 sm:pb-6 text-left">
            <p>
              {faq.answer}
            </p>
          </div>
        )}
      </div>
      {/* Rest of the FAQ items */}
    </div>
  );
}


export default LandingFAQ;

import { RemirrorJSON } from "remirror";

const extractOriginalEntry = (doc: RemirrorJSON): string => {
  let string = '';

  const processNode = (node: any) => {
    if (node.type === 'text' && (!node.marks || node.marks.length === 0)) {
      string += node.text ?? '';
    }

    if (node.content) {
      node.content.forEach(processNode);
    }
  };

  doc.content?.forEach((node) => {
    if (node.type === 'paragraph') {
      processNode(node);
      string += '\n';
    }
  });

  return string;
};

export default extractOriginalEntry;
import { Entry } from "./EntryEditor/Entry"
import { useState, useEffect } from "react"
import { GradientThumb } from "./Gradient/GradientThumb"
import { Suggestion } from "./Suggestions/Suggestion"
import { SuggestionType } from "./Suggestions/Suggestion"
import { useMultipleEntryReflection } from "src/lib/getSmartCardQuestons"
import { useEntries } from "src/lib/useEntries"
import { isYesterday } from 'date-fns';
import getLastEntryFromYesterday from "src/lib/getLastEntryFromYesterday";
import { FeelingsModuleData, SidebarModuleType } from "src/components/EntryEditor/Sidebar/SidebarModule";

export const PersonalizedBox = ({
  onSelect,
  uid,
}: {
  onSelect: (suggestion: Suggestion, theme: string) => void;
  uid: string;
}) => {
  const entries = useEntries(uid);
  const lastEntryFromYesterday = getLastEntryFromYesterday({ entries });

  const yesterdayEntries = entries.filter((entry) => isYesterday(entry.date_created))
  const { resultText: yesterdayReflection, loading } = useMultipleEntryReflection(
    uid,
    SuggestionType.YESTERDAY_REFLECTION,
    yesterdayEntries.length !== 0 
  );

  const useSuggestions = (): Suggestion[] => {
    let suggestions: Suggestion[] = [];
    suggestions.push({
        type: SuggestionType.YESTERDAY_REFLECTION,
        title: "Reflect on Yesterday",
        theme: "default",
        cardPrompt: "Start New Journal",
        description: yesterdayReflection,
      });    
    return suggestions;
  };

  return (
    <div className="w-full">
      {useSuggestions().map((suggestion, index) => (
        <PersonalizedCard
          key={index}
          suggestion={suggestion}
          entry={lastEntryFromYesterday}
          loading={loading}
          onClick={() => {
            onSelect(suggestion, suggestion.theme ? suggestion.theme : "default");
          }}
        />
      ))}
    </div>
  );
};

export const PersonalizedCard = ({
  suggestion,
  entry,
  onClick,
  loading,
}: {
  suggestion: Suggestion;
  entry: Entry | null,
  onClick: () => void;
  loading: boolean;
}) => {
    const data = entry?.sidebarModules && 
        entry?.sidebarModules.find(
            (module) => module.type === SidebarModuleType.FEELINGS)?.data as FeelingsModuleData;

        const [buttonVisible, setButtonVisible] = useState(false);

        useEffect(() => {
            if (!loading) {
              setButtonVisible(true);
            }
          }, [loading]);

  return (
         <div
            key={suggestion.title}
            className="w-full lg:w-full sm:w-1/2 h-48 pl-4 py-2 cursor-pointer hover:scale-105 transition-all duration-500 text-tifla-green"
            onClick={onClick}>
            <div className=" rounded-lg overflow-hidden shadow-md bg-white flex flex-col justify-between">
                <div className="p-6 h-auto">
                    <div className="w-full text-3xl font-garamond mb-2">
                        {suggestion.title}

                    </div>
                    {entry !== null ? (
  <div className="w-full text-sm">
    <div className="w-full shadow-lg rounded-md overflow-hidden">
      {entry?.gradientParams && data && data.feelings && (
        <GradientThumb params={entry.gradientParams} feelingsModuleData={data} />
      )}
      <div className="p-3">
        <div className="text-sm font-quicksand font-bold text-tifla-green">
          {entry?.date_created.toLocaleString(window.navigator.language, { weekday: 'long' })}
        </div>
        <div className="text-2xl font-quicksand font-semibold text-tifla-green">
          {entry?.date_created.getDate()}
        </div>
      </div>
      <div className="w-full">
        <div className="text-sm overflow-hidden mt-1 h-[80px] text-tifla-green font-medium">
          {entry?.summary &&
            entry?.summary.split("\n").map((line, index) => (
              <div className="flex flex-row">
                <div className="mx-1">-</div>
                <div key={index}>{line.replace("-", "")}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
    <div className="w-full justify-items-center my-5 text-sm">
                    {buttonVisible && <button>Create Reflection Journal</button>}
                    </div>
  </div>
) : (
  <div className="w-full text-sm">
    <div>You don't have an entry from yesterday. Come back again for a reflective journal. </div>
  </div>
)} 
                    
                </div>
        </div>
    </div>
  )
};

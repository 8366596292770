import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { defaultPalette, pickGradientColors } from './Palette';
import { FeelingsModuleData } from '../EntryEditor/Sidebar/SidebarModule';
import { useDebounce } from 'usehooks-ts';
import { Sketch } from './Sketch';

export type GradientParams = {
    time: number,
    colorFreq: number[]
}

export const defaultGradientParams = (): GradientParams => {
    return {
        time: Math.random(),
        colorFreq: [10 + Math.random() * 0.5, 10 + Math.random() * 0.5 ]
    }
}

export const extractColorsFromFeelingsData = (feelingsModuleData: FeelingsModuleData) => {
    const colors = feelingsModuleData.feelings.map(feeling => {
        return new THREE.Color(feeling.color);
    })
    return colors;
}

const BackgroundGradient = ({
    feelingsModuleData,
    params,
    updateParams,
    shortAnimateCount,
} : {
    feelingsModuleData: FeelingsModuleData | undefined
    params: GradientParams,
    updateParams: (params: GradientParams) => void,
    shortAnimateCount: number,
}) => {
    const [sketch, setSketch] = useState<Sketch | null>(null);
    const debouncedFeelingsModuleData = useDebounce(feelingsModuleData, 1000);
    const ref = useRef(null);

    const onAnimFinished = (params: GradientParams) => {
        updateParams(params);
    }

    const getPalette = () => {
        if (feelingsModuleData && feelingsModuleData.feelings) {
            const allColors = extractColorsFromFeelingsData(feelingsModuleData);
            const palette = pickGradientColors(allColors);
            return palette;
        }
        else {
            return defaultPalette();
        }
    }

    useEffect(() => {
        if (shortAnimateCount > 0) {
            sketch?.shortAnimate();
        }
    }, [shortAnimateCount])

    useEffect(() => {
        const palette = getPalette();
        if (palette) {
            sketch?.updatePalette(palette);
        }
    }, [debouncedFeelingsModuleData])

    useEffect(()  => {
        if (ref.current) {
            const palette = getPalette();
            setSketch(new Sketch(ref.current, params ? params : defaultGradientParams(), onAnimFinished, palette))
        }
    }, [])

    return <div className="relative -mt-20">
        <div className="fixed w-full h-[110vh] opacity-20 -z-30 blur-[0px]" ref={ref} />
    </div>
}


export { BackgroundGradient };
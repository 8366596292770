import { useState } from "react"
import { db } from "../firebase"
import { collection, orderBy, query } from "firebase/firestore"
import { useCollection } from 'react-firebase-hooks/firestore';
import { Entry, entryConverter } from "../components/EntryEditor/Entry";

const formatEntry = (doc: any) => {
    const data = doc.data();
    return {
        docId: doc.id,
        text: data.text,
        date_created: data.date_created ? data.date_created.toDate() : undefined,
        pmJSON: data.pmJSON ? data.pmJSON : [],
    }
}

const useEntries = (uid: string): Entry[] => {
    const [value, loading, error] = useCollection(
        query(collection(db, "users", uid, "entries").withConverter(entryConverter), orderBy("date_created", "desc"))
        //TODO add sorting
    );

    if (value) {
        return value.docs.map( (doc) => doc.data() as Entry);
    }
    else {
        return [];
    }
}

export {useEntries, formatEntry}
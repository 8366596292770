import type { RemirrorJSON } from '@remirror/core';

const proseMirrorToString = (doc: RemirrorJSON): string => {
    var string = "";

    if (doc.content) {
        doc.content.forEach( (node) => {
            
            if (node.content) {
                node.content.forEach( (node) => {
                    if (node.text) {
                        string += node.text;
                    }
                });
                string += "\n"
            }
        });
    }
    
    return string;
}

export const proseMirrorToStringExcludeTifla = (doc: RemirrorJSON): string => {
    var string = "";
    
    if (doc.content) {
        doc.content.forEach( (node) => {
            
            if (node.content) {
                node.content.forEach( (node) => {
                    let skip = false;
                    //@ts-ignore
                    if (node.marks && node.marks[0].type === "tifla") {
                        skip = true;
                    }
                    if (node.text && !skip) {
                        string += node.text;
                    }
                });
                string += "\n"
            }
        });
    }
    
    return string;
}

export default proseMirrorToString;
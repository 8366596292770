import { getDownloadURL, ref } from "firebase/storage";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "src/App";
import { Entry } from "src/components/EntryEditor/Entry";
import { FeelingsModuleData, SidebarModuleType } from "src/components/EntryEditor/Sidebar/SidebarModule";
import { GradientThumb } from "src/components/Gradient/GradientThumb";
import { storage } from "src/firebase";
import { proseMirrorToStringExcludeTifla } from "src/lib/proseMirrorToString";
import { useEntries } from "src/lib/useEntries";

const Entries = () => {
    const { currentUser } = useContext(AuthContext);
    const entries = useEntries(currentUser.uid);

    return <div className="w-full">
        {entries ? <EntriesList entries={entries} /> : <div>Loading...</div>}
    </div>
}

const EntryMonth = (
    {
        month,
        entries,
    }: {
        month: string,
        entries: Entry[],
}) => {
    return <div className="w-full">
        <div className="w-full ml-8 mt-8 text-6xl font-garamond">{month}</div>
        <div className="w-full flex flex-row flex-wrap">
            {
                entries.map((entry) => {
                    return <EntryCard key={entry.docId} entry={entry} />
                })
            }
        </div>
    </div>
}

const EntriesList = (
    {
        entries
    } : {
        entries: Entry[]
}) => { 

    const getEntriesByMonth = () => {
        if (!entries || entries.length === 0) {
            return;
        }
        let entryMonths = [];
        let currentMonthOfEntries:Entry[] = [];
        let currentMonth = entries[0].date_created.toLocaleString("en-US", { month: "long" });
        
        for (let i = 0; i < entries.length; i++) {
            const month = entries[i].date_created.toLocaleString("en-US", { month: "long" });
            console.log(month)
            if (month === currentMonth) {
                currentMonthOfEntries.push(entries[i]);
            }
            else {
                entryMonths.push(
                    <EntryMonth key={month} month={"" + currentMonth} entries={currentMonthOfEntries} />);

                currentMonth = month;
                currentMonthOfEntries = [entries[i]];
            }
        }
        entryMonths.push(
            <EntryMonth key={currentMonth} month={"" + currentMonth} entries={currentMonthOfEntries} />);
        return entryMonths;
    }

    return <div className="container mx-auto text-tifla-green">
        
        <div className="flex flex-row flex-wrap">    
            {
                getEntriesByMonth()
            }
        </div>
    </div>
}

const EntryCard = (
    {
        entry
    }: {
        entry: Entry
}) => {
    const navigate = useNavigate();

    const getDetails = () => {
        if (entry.summary) {
            return entry.summary.split("\n").map((line, index) => 
                <div className="flex flex-row">
                    <div className="mx-1">
                        -
                    </div>
                    <div key={index}>{line.replace("-", "")}</div>
                </div>)
        }
        else {
            return proseMirrorToStringExcludeTifla(entry.pmJSON).substring(0,100) + "...";
        }
    }

    const getVisual = () => {
        if (entry.reflectionImagePath) {
            return <div className="absolute w-[150%] -ml-[25%] -z-10">
                <ReflectionImageWithLoading reflectionImagePath={entry.reflectionImagePath} />
                <div className="absolute top-0 left-0 w-full h-full bg-white/50">

                </div>
            </div>
        }
        else if (entry.gradientParams) {
            const data = entry.sidebarModules && 
            entry.sidebarModules.find(
                (module) => module.type === SidebarModuleType.FEELINGS)?.data as FeelingsModuleData
            if (data && data.feelings) {
                return  <GradientThumb
                    params={entry.gradientParams}
                    feelingsModuleData = {data} />
            }
        }
        return <div></div>
    }

    return <div className="w-full sm:w-1/3">
        <div 
            className="relative m-8 flex flex-col cursor-pointer hover:scale-105 transition-all duration-500"

            onClick={() => navigate(`/entry/${entry.docId}`)}
            >
            
            <div className="flex flex-row  space-x-4">
                <div className="w-2/5 shadow-lg rounded-md overflow-hidden relative">
                    {getVisual()}
                
                    <div className="p-3" >
                        <div className="text-sm font-quicksand font-bold text-tifla-green">
                            {entry.date_created.toLocaleString(window.navigator.language, {weekday: 'long'}) }

                        </div>
                        <div className="text-2xl font-quicksand font-semibold text-tifla-green">
                            {entry.date_created.getDate()}
                        </div>
                        
                    </div>
                </div>
                <div className="w-3/5">
                    <div className="text-sm overflow-hidden mt-1 h-[150px] text-tifla-green font-medium">
                        { getDetails()}
                    </div>
                </div>
            </div>

            
            
        </div>
    </div>

}

const ReflectionImageWithLoading = (
    {
        reflectionImagePath
    } : {
        reflectionImagePath: string
    }
) => {

    const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);

    useEffect(() => {
        getDownloadURL(ref(storage, reflectionImagePath))
            .then((url) => {
                setImgUrl(url);
            })
            .catch((error) => {
                console.log("error downloading", error)
            });
    }, [])

    if (!imgUrl) {
        return <div className="absolute left-[50%] top-[75px]">
            <div className="loader"></div>
        </div>
    }
    else {
        return <motion.img initial={{opacity: 0}} animate={{opacity: 100}} transition={{duration: 1}} src={imgUrl} />
    }

}

export {Entries};
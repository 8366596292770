export const LandingHero = () => {
    return <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 pt-24 sm:pt-36">
        <div className="max-w-2xl mx-auto text-center">
            {<h1 className="text-lg text-gray-600 font-garamond"> Amble Through Life: An AI-Assisted Journal </h1>}
            <p className="mt-5 text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-6xl lg:leading-tight font-garamond">
                Your smart journal  
                <span className="relative inline-flex sm:inline">
                    <span className="bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] blur-lg filter opacity-30 w-full h-full absolute inset-0"></span>
                    <span className="relative"> is here</span>
                </span>
            </p>

        <h1 className="text-lg text-gray-600 font-garamond">  </h1>

        <p className="mt-8 text-base text-xl font-garamond">We take the therapeutic art of journaling and supercharge it with fun, AI-guided prompts, creating a playful, yet profound, space for personal growth.</p>
        <p className="mt-8 text-base text-xl font-garamond"> Just a few minutes a day with Amble will deepen your relationship with yourself and your priorties. And just as you learn about yourself, Amble learns about you.</p> 
        <div className="px-8 sm:items-center sm:justify-center sm:px-0 sm:space-x-5 sm:flex mt-9">
            <a
                href="https://tazs7mxpndu.typeform.com/to/cvTMTUc8"
                title=""
                className="inline-flex items-center justify-center w-full px-8 py-3 text-3xl font-garamond font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                role="button"
            >
                Sign Up For the Waitlist
            </a>
        </div>
        <p className="mt-5 text-base text-gray-500 font-garamond">Currently open to beta users only </p>
    </div>
</div>
}
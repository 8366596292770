import { useEffect, useState } from "react";
import { FeelingsModuleData, ModuleData, SidebarModule, SidebarModuleType } from "../SidebarModule";
import { capitalizeFirstLetter, getGPTFeelings } from "src/lib/getGPTFeelings";
import { Feeling, mergeFeelings } from "./Feeling";
import { AnimatePresence, motion } from "framer-motion";
import { AddIcon, TrashIcon } from "src/components/Icons";

const FeelingsModule = (
    {
        module,
        onModuleDataChanged,
        debouncedJournalText,
    } : {
        module: SidebarModule,
        onModuleDataChanged : (data: ModuleData) => void,
        debouncedJournalText: string
}) => {
    const [fetchNewFeelings, setFetchNewFeelings] = useState(false);
    const data = module.data as FeelingsModuleData;
    const [addFeelingValue, setAddFeelingValue] = useState("");
    const [focusAddFeeling, setFocusAddFeeling] = useState(false);

    const onFeelingPercentChanged = (feelingName: string, percent: number) => {
        const newFeelings = data.feelings.splice(0);
        newFeelings.find((feeling) => feeling.name === feelingName)!.percent = percent;
        onModuleDataChanged({
            feelings: newFeelings
        });
    };  

    const onFeelingRemoved = (feelingName: string) => {
        const index = data.feelings.findIndex((feeling) => feeling.name === feelingName);
        if (index !== -1) {
            let newFeelings = data.feelings.splice(0);
            newFeelings.splice(index, 1);
            onModuleDataChanged({
                feelings: newFeelings
            });
        }
    }

    const onFeelingAdded = (feelingName: string) => {
        if (!data.feelings.some((feeling) => feeling.name === feelingName)) {
            const newFeeings = [...data.feelings, {name: feelingName, percent: null}]
            onModuleDataChanged({
                feelings: newFeeings
            });
        }
    }

    useEffect(() => {
        //console.log("debounced text changed", debouncedJournalText)
        if (!fetchNewFeelings) {
            setFetchNewFeelings(true); // don't fetch the first time
        }
        else {
            console.log("fetching new feelings");
            getGPTFeelings(debouncedJournalText, (newFeelings: Feeling[]) => {
                //console.log("got new feelings", newFeelings);
                onModuleDataChanged({
                    feelings: data.feelings ? mergeFeelings(data.feelings, newFeelings) : newFeelings
                });
            });
        }
        
    }, [debouncedJournalText]);

    const addFeeling = (name:string) => {
        onFeelingAdded(capitalizeFirstLetter(name));
        setAddFeelingValue("");
    }

    return <div onFocus={() => {setFocusAddFeeling(true)}} onBlur={() => {setFocusAddFeeling(false)}}>
        <div className="text-md font-bold">Feelings</div>
        <AnimatePresence>
            {data.feelings && data.feelings.map((feeling: Feeling, index: React.Key) => {
                    return <FeelingItem
                        key={index}
                        feeling={feeling}
                        updatePercent={(percent: number) => {onFeelingPercentChanged(feeling.name, percent)}}
                        onRemoveFeeling={() => {onFeelingRemoved(feeling.name)}} 
                    />
                    }
                )}

            <AddFeeling 
                key={data.feelings ? data.feelings.length : 0} 
                value={addFeelingValue} 
                onValueChange={(e) => {setAddFeelingValue(e.target.value)}} 
                addFeeling={addFeeling} 
                refocus={focusAddFeeling}/>
        </AnimatePresence>
        
    </div>
}

const AddFeeling = ({
    value,
    onValueChange,
    addFeeling,
    refocus
} : {
    value: string,
    onValueChange: (e:any ) => void,
    addFeeling: (feelingName: string) => void,
    refocus: boolean
}) => {

    const keyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            addFeeling((e.target as any).value);
        }
    }

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, [])

    const animationVariants = {
        hidden: { opacity: 0, y: 0, transition: { duration: 0.3 } },
        loaded: { opacity: 1, y: 0, transition: { duration: 0.3 } },
        visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
        exit: { opacity: 0, transition: { duration: 0.03 } }
    }

    return <motion.div 
        className="flex flex-row mt-2"
        initial={loaded ? "loaded" : "hidden" }
        animate="visible"
        exit="exit"
        variants={animationVariants}
        key="add-feeling-div"
        >

        <input 
            autoFocus={refocus}
            placeholder="add new" 
            onKeyDown={keyPressed} 
            className="border-b border-neutral-500 bg-transparent focus:outline-none text-xs w-full" 
            type="text" 
            value={value}
            onChange={onValueChange}></input>
        
    </motion.div>
}

export const FeelingItem = (
    {
        feeling,
        updatePercent,
        onRemoveFeeling,
        
    } : {
        feeling: Feeling,
        updatePercent: (percent: number) => void,
        onRemoveFeeling: () => void,
}) => {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, [])

    const animationVariants = {
        hidden: { opacity: 0, y: 0, transition: { duration: 0.3 } },
        visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
        exit: { opacity: 0, maxHeight: 0, transition: { duration: 0.5 } }
    }

    return <motion.div
        key={feeling.name}
        initial={loaded ? "loaded" : "hidden" }
        animate="visible"
        exit="exit"
        variants={animationVariants}>

        <div className={`w-full group/feeling flex flex-row space-x-2 items-center ${feeling.percent ? "" : "opacity-50"}`}>
            
            <div className="w-2/5 text-xs">{ feeling.name }</div>
            <div className="text-xs">
                {feeling.percent ? 
                    ("" + feeling.percent + "%")
                : "  "}
            </div>
            <div className="w-3/5 flex flex-row items-center">
                <input 
                    className="myslider appearance-none bg-gray-600 w-full h-[1px] rounded-full opacity-70 ease-in-out duration-150 hover:opacity-100"
                    type="range"
                    min="0"
                    max="100"
                    value={feeling.percent !== null ? feeling.percent : 50}
                    onChange={(e) => {updatePercent(parseInt(e.target.value))}} />
            </div>
            <div className="cursor-pointer scale-75 opacity-0 group-hover/feeling:opacity-50 transition-all hover:scale-110"
                onClick={onRemoveFeeling}>
                {TrashIcon}
            </div>
        </div>
    </motion.div>
}

export const findFeelingsModuleData = (sidebarModules: SidebarModule[]) => {
    return sidebarModules.find((module) => module.type === SidebarModuleType.FEELINGS)?.data as FeelingsModuleData
}

export const topFeelings = (feelingsModuleData: FeelingsModuleData) => {
    
    if (feelingsModuleData && feelingsModuleData.feelings) {
        let feelings = [...feelingsModuleData.feelings];
        
        feelings.sort((a:Feeling, b:Feeling) => {
            return b.percent !== null && a.percent !== null 
                ? b.percent - a.percent
                : 0;
        });
        
        return feelings.slice(0, 3);
    }
    return [];
}


export function aggregateTopFeelings(allFeelings: Feeling[]): Feeling[] {
    const feelingsMap: { [key: string]: {percent: number, color: string}} = {};

    allFeelings.forEach(feeling => {
        if (feeling.name in feelingsMap) {
            if (feeling.percent !== null) {
                feelingsMap[feeling.name].percent += feeling.percent;
            }
        } else {
            feelingsMap[feeling.name] = {percent: feeling.percent !== null ? feeling.percent : 0, color: feeling.color};
        }
    });

    let aggregatedFeelings: Feeling[] = Object.entries(feelingsMap).map(([name, {percent, color}]) => ({
        name: name,
        percent: percent,
        color: color
    }));

    aggregatedFeelings.sort((a, b) => (b.percent ?? 0) - (a.percent ?? 0));
    
    return aggregatedFeelings.slice(0, 5);
}

export {FeelingsModule}
import { addDoc, collection, doc } from "firebase/firestore";
import { useState } from "react";
import { useDocumentOnce } from "react-firebase-hooks/firestore";
import { Entry, entryConverter } from "src/components/EntryEditor/Entry";
import { db } from "src/firebase";

const useEntry = (uid: string, entryId: string): Entry | undefined => {
    
    const entryRef = doc(db, "users", uid, "entries", ""+entryId).withConverter(entryConverter);

    const [value, loading, error] = useDocumentOnce(
        entryRef
    );

    if (value) {
        return value.data() as Entry;
    }
    else {
        return undefined;
    }
}

const createNewEntry = (uid: string) => {
    return addDoc(collection(db, "users", uid, "entries"), {
        text: "",
        date_created: new Date(),
        pmJSON: [],
        feelings: []
    });
}

export { useEntry, createNewEntry }
import { RemirrorJSON } from "remirror";
import { PromptIcon } from "../Icons";

export enum SuggestionType {
    FOLLOW_UP_QUESTIONS = "PROMPTS",   
    CBT_WORKSHEET = "CBT",
    //REFLECTION = "Reflections",
   // JOURNEY="GUIDED JOURNALING",
    MULTIPLE_PAST_REFLECTION="Reflections",
    YESTERDAY_REFLECTION="Yesterday's Reflection",
    GRATITUDE="GRATITUDE",
    MINDFULNESS="MINDFULNESS",
}

export const SUGGESTION_TYPE_TO_PROMPT = {
    [SuggestionType.FOLLOW_UP_QUESTIONS]: "You are a kind therapist and life coach. In a numbered list, what are three short, kind & effective follow-up questions that you will ask someone in response to their following journal entry to help elaborate and process emotions. Do not ask obvious questions: \n",
    //[SuggestionType.REFLECTION]: "What is one short, kind follow-up reflection question that someone can ask themselves after writing the following journal entry to help them think more deeply about the situation: \n",
    [SuggestionType.CBT_WORKSHEET]: `What is one potential Team-CBT exercise by David Burns (in not more than 200 characters) with its official name that can help me with the following journal entry focused on things I can do more immediately:  \n`,
  //  [SuggestionType.JOURNEY]: "Write three short bulleted follow-up questions that someone can ask themselves (in a first person voice) as they journal using the following journaling intention and their journal entry so far : " ,
    [SuggestionType.MULTIPLE_PAST_REFLECTION]: "Summarize my past few journal entries given here. Format response (limit it to 200 characters) starting with 'Your themes from the week were: ' and do not say anything generic or refer to yourself as an AI model \n" ,
    [SuggestionType.YESTERDAY_REFLECTION]:"Summarize some top themes from my journal entries from yesterday given here. For response (limit it to 200 characters) starting with 'Your themes from yesterday were: ' and do not say anything generic \n" ,
    [SuggestionType.GRATITUDE]: "",
    [SuggestionType.MINDFULNESS]: "",
}

export const SUGGESTION_TYPE_TO_ICON = {
    [SuggestionType.FOLLOW_UP_QUESTIONS]: PromptIcon,
    [SuggestionType.CBT_WORKSHEET]: PromptIcon,
  //  [SuggestionType.JOURNEY]: PromptIcon
}

export type Suggestion = {
    type: SuggestionType,
    pmJSON?: RemirrorJSON,
    title: string,
    description: string,
    exerciseJSON?: string,
    theme?: string,
    cardPrompt?: string
}

export type SuggestionList = {
    type: SuggestionType,
    suggestions: Suggestion[]
}

export const DEFAULT_SUGGESTION_TYPES = 
    [
        SuggestionType.FOLLOW_UP_QUESTIONS, 
        SuggestionType.CBT_WORKSHEET,
        SuggestionType.MULTIPLE_PAST_REFLECTION,
        SuggestionType.YESTERDAY_REFLECTION
    ]


    export const DROPDOWN_SUGGESTION_TYPES = 
    [
        //SuggestionType.REFLECTION, 
        SuggestionType.FOLLOW_UP_QUESTIONS, 
        SuggestionType.CBT_WORKSHEET,
      //  SuggestionType.JOURNEY,
    ]
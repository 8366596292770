import { useEffect, useState } from "react";
import { BasicButton } from "../BasicButton"
import { getImage } from "src/lib/TiflaGPTApi";
import { AddImage, CircleCheck, Refresh } from "../Icons";
import { motion } from "framer-motion";
import { uploadImage } from "src/lib/uploadImage";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "src/firebase";
import { Feeling } from "./Sidebar/FeelingsModule/Feeling";

const BASIC_IMAGE_PROMPT = "An impressionist painting of the feelings of ";

const GenerateImage = (
    {
        uid,
        reflectionImagePath,
        saveImage,
        topThreeFeelings
    } : {
        uid: string,
        reflectionImagePath: string,
        saveImage: (full_path: string) => Promise<void>,
        topThreeFeelings: Feeling[]
    }
) => {

    const [expanded, setExpanded] = useState(false);
    const [imageOptions, setImageOptions] = useState<string[]>([])
    const [generating, setGenerating] = useState<boolean>(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
    const [chosenImage, setChosenImage] = useState<string>("");
    const [chosenImageUrl, setChosenImageUrl] = useState<string>("");
    const [savingImage, setSavingImage] = useState<boolean>(false);
    const [prompt, setPrompt] = useState<string>(BASIC_IMAGE_PROMPT + topThreeFeelings.map((feeling) => feeling.name).join(", ") + ".");

    useEffect(() => {
        setPrompt(BASIC_IMAGE_PROMPT + topThreeFeelings.map((feeling) => feeling.name).join(", ") + ".");
    }, [topThreeFeelings])

    const generativeOptions = () => {
        setGenerating(true);
        setSelectedImageIndex(-1);

        getImage(prompt).then(
            (res) => {
                setGenerating(false);
                if (res) {
                    setImageOptions(res.map((image: {b64_json: string}) => image.b64_json));
                }
            }
        ).catch(
            (err) => {
                setGenerating(false);
                console.log(err);
            }
        )
    }

    const uploadAndSaveImage = (b64_json: string) => {
        setSavingImage(true);
        uploadImage(uid, b64_json)
            .then((uploadResult) => {

                if (uploadResult.ref.fullPath) {
                    
                    saveImage(uploadResult.ref.fullPath)
                        .then(() => {
                            setSavingImage(false);
                        }).catch((err) => {
                            console.log(err);
                            setSavingImage(false);
                        });
                }
            });
    }

    useEffect(() => {
        if (reflectionImagePath) {
            getDownloadURL(ref(storage, reflectionImagePath))
                .then((url) => {
                    if (url) {
                        setChosenImageUrl(url);
                    }
                });
        }
    }, [])
    
    return <div className="w-full">
            <ReflectionImage chosenImage={chosenImage} chosenImageUrl={chosenImageUrl} uploading={savingImage}/>

            <div className={`w-full h-auto border border-tifla-green  flex flex-col  px-4 transition-all overflow-hidden duration-500
                                ${expanded ? "py-2 max-w-[600px] rounded-2xl max-h-[400px] " : "py-1 max-h-10 rounded-2xl hover:bg-tifla-green/20 cursor-pointer max-w-[300px]"}`}>
            
            <div className="w-full flex flex-row text-lg items-center space-x-2"
                onClick={() => {setExpanded(expanded => !expanded)}}>
                <div className="fill-tifla-green"> {AddImage}</div>
                <div>Generate Reflection Image </div>
                
            </div>
            <div className={`flex flex-col text-xs transition-opacity duration-500 ${expanded ? " " : "opacity-0"}`}>

                <div className="flex flex-row justify-between items-end mb-2">
                    <div className="flex flex-col w-3/4">
                        <div className={"font-bold mt-2"}>
                            AI Prompt:
                        </div>
                        <div className="text-xs italic">
                            <textarea className="w-full bg-transparent resize-none focus:outline-none" value={prompt} onChange={e => {setPrompt(e.target.value)}} />
                        </div>
                    </div>
                    <div className="">
                        <GenerateButton generating={generating} generateMore={imageOptions.length > 0} onClick={generativeOptions} />    
                    </div>
                </div>
                
                <ThreeOptions imageOptions={imageOptions} selectedIndex={selectedImageIndex} setSelectedImageIndex={setSelectedImageIndex} />
                <div className="mb-2"/>
                <ChooseImageButton show={imageOptions.length > 0} enabled={selectedImageIndex >= 0 && selectedImageIndex <= 2} 
                        onChoose={() => {
                            setChosenImage(imageOptions[selectedImageIndex]);
                            setChosenImageUrl("");
                            uploadAndSaveImage(imageOptions[selectedImageIndex]);
                            setExpanded(false);
                        }}/>
            </div>
        </div>
    </div>
}

const ReflectionImage = (
    {
        chosenImage,
        chosenImageUrl,
        uploading
    } : {
        chosenImage: string,
        chosenImageUrl: string,
        uploading: boolean
    }
) => {

    const getImage = () => {
        if (chosenImageUrl) {
            return <motion.img initial={{opacity: 0}} animate={{opacity:1}} transition={{duration: 0.5}} className="mb-4 rounded-lg" src={chosenImageUrl} />
        }
        else if (chosenImage) {
            return <motion.img initial={{opacity: 0}} animate={{opacity:1}} transition={{duration: 0.5}} className="mb-4 rounded-lg" src={"data:image/jpeg;base64," + chosenImage} />
        }
        else {
            return <div></div>
        }
    }

    return <div className="relative w-fit">
        {getImage()}
        {uploading && 
            <div className="absolute left-full top-0 flex flex-row items-center space-x-1 pl-3">
                <div className="loader"/>
                <div>Saving...</div>
            </div>}
    </div>
    
}

const GenerateButton = (
    {
        generating,
        generateMore,
        onClick
    } : {
        generating: boolean,
        generateMore: boolean,
        onClick: () => void
    }
) => {
    return <div className={`flex flex-row text-center fill-tifla-green items-center justify-center space-x-2 border border-tifla-green font-bold rounded-lg p-2 text-sm w-fit  transition-all hover:bg-tifla-green/20
        ${generateMore ? "" : ""} 
        ${generating ? "opacity-50" : "cursor-pointer "}`}
        onClick={onClick}>
    <div>
    { generating ? <div>Generating</div> : 
        (generateMore ? Refresh : <div>Generate</div>) }
    </div>
    {generating && <div className="loader"/> }

    </div>
}

const ChooseImageButton = (
    {
        show,
        enabled,
        onChoose,
    } : {
        show: boolean,
        enabled: boolean,
        onChoose: () => void
    }
) => {
    if (!show) return <></>

    return <div className={`flex flex-row items-center justify-center space-x-2 text-white font-bold rounded-lg px-4 py-2 my-2 text-sm w-fit transition-all cursor-pointer 
                            ${enabled ? "bg-green-800 hover:bg-green-900" : "bg-green-800/50"}`}
                onClick={onChoose}>
        Save Image
        
    </div>
}

const ThreeOptions = (
    {
        selectedIndex,
        imageOptions,
        setSelectedImageIndex,
    } : {
        selectedIndex: number,
        imageOptions: string[],
        setSelectedImageIndex: (index: number) => void
    }
) => {

    const list = {
        visible: { 
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: .4,
            }
        },
        hidden: { 
            opacity: 0,
            transition: {
                when: "afterChildren",
                duration: 5
            }
        },
    }

    return <motion.ul initial="hidden" animate={"visible"} variants={list} 
        className={`flex flex-row space-x-4 pl-0 my-0 transition-all duration-500 pr-4 -mr-4
                    ${imageOptions.length > 0 ? "max-h-[200px]" : "max-h-0"}`}>
    {imageOptions.map((imageOption, index) => 
        <ImageOption 
            key={index} 
            source={imageOption} 
            selected={selectedIndex === index}
            onSelect={() => {setSelectedImageIndex(index)}}/>)
    }
    </motion.ul>
}

const ImageOption = (
    {
        selected,
        source,
        onSelect,
    }: {
        selected: boolean,
        source: string,
        onSelect: () => void,
}) => {

    const item = {
        visible: { 
            opacity: 1,
            transition: {
                ease: "easeOut",
                duration: 2,
            }
        },
        hidden: { opacity: 0},
    }

    return <motion.li variants={item} className="w-1/3 mt-2 relative list-none ml-0">
        <div className={`rounded-md overflow-hidden border-4 cursor-pointer  transition-all duration-500 
                        ${selected ? " border-emerald-500/30 border-[10px]" : "hover:border-emerald-500 border-transparent "}
                        `}
             onClick={onSelect}>
            {selected && <div className="absolute -right-3 -top-3 z-50 bg-emerald-500 rounded-full p-1 fill-white">
                {CircleCheck}    
            </div>}
            <img className="hover:scale-105 transition-all duration-500" src={"data:image/png;base64," + source}/>
        </div>
    </motion.li>
}


export {GenerateImage}

export const WrapupDropdownItem = ({
    onSelect
}: {
    onSelect: () => void
}) => {
    
    return <div className="flex flex-col"
                onClick={onSelect}>
        <div className="text-xs font-bold mb-2">WRAPUP</div>
        <div>
            Wrap up todays' entry. Create a summary of topics and feelings, and create an AI generated image.
        </div>
    </div>
}
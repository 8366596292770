import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FeelingItem } from "../EntryEditor/Sidebar/FeelingsModule/FeelingsModule";

const FINAL_PERCENTS = [70, 20, 34];

export const FeatureFeelings = () => {
    
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [animationStep, setAnimationStep] = useState<number>(-1);

    const [feelingPercents, setFeelingPercents] = useState<number[]>([50,50,50]);

    useEffect(() => {
        if (isInView) {
            setAnimationStep(0);
        }
    }, [isInView])

    useEffect(() => {
        if (animationStep >= 1) {
            var newPercents: number[] = [];
            for (var i = 0; i < feelingPercents.length; i++) {
                newPercents[i] = FINAL_PERCENTS[i] + (feelingPercents[i] - FINAL_PERCENTS[i]) * .98;
            }
            console.log("animate pct")
            setTimeout(()=> {
                setFeelingPercents(newPercents);
            }, 10)
            
        }
        
    }, feelingPercents);

    useEffect(() => {
        switch(animationStep) {
            case 0: 
                setTimeout(() => setAnimationStep(1), 1000); break;
            case 1: 
                setTimeout(() => setAnimationStep(2), 1000); break;
            case 2: 
                setFeelingPercents([51,50,50]); break;
        }
    }, [animationStep])

    return <div className="w-full flex flex-col space-y-8 items-center pt-48">
        <div className="text-5xl font-garamond text-center">
            Emotional Reflection
        </div>
        <div className="text-md max-w-[700px] text-center">
            Amble auto-detects feelings in your entry, allowing you to reflect on them and rate them on a scale of 0 - 100.
        </div>

        
        <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0 transition-all duration-[1000ms] ">
            <div className="text-xl w-full sm:w-[500px] bg-white/30 p-8 rounded-xl">
                <div className={`transition-all duration-[1000ms] ${animationStep >= 0 ? "opacity-100" : "opacity-0"}`}>
                    <span>
                        Today was an interesting day.  I went to get coffee with a friend, but they were 15 minutes late. I was surprised because they're usually very reliable.
                    </span><span className="blinking-cursor">|</span>
                </div>
            </div>
            <div className={`${animationStep >= 1 ? "max-w-[300px]" : "max-w-[0px]"} transition-all duration-[1000ms] overflow-hidden `}>
                <div className="flex flex-col w-full bg-white/40 rounded-lg p-4">
                    <div className="font-bold">Feelings </div>
                    <FeelingItem feeling={{name: "Frustration", percent: Math.trunc(feelingPercents[0]), color: ""}} updatePercent={() => {}} onRemoveFeeling={() => {}}/>
                    <FeelingItem feeling={{name: "Surprise", percent: Math.trunc(feelingPercents[1]), color: ""}} updatePercent={() => {}} onRemoveFeeling={() => {}}/>
                    <FeelingItem feeling={{name: "Loneliness", percent: Math.trunc(feelingPercents[2]), color: ""}} updatePercent={() => {}} onRemoveFeeling={() => {}}/>
                </div>
                
            </div>
        </div>
        
        
        <div ref={ref} />
        
    </div>
}
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { RemirrorJSON } from "remirror";
import { useDebounce } from "usehooks-ts";
import { DEFAULT_SUGGESTION_TYPES, Suggestion, SuggestionList,DROPDOWN_SUGGESTION_TYPES } from "../../Suggestions/Suggestion";
import { getAllSuggestionLists } from "src/lib/getAllSuggestionLists";
import proseMirrorToString from "src/lib/proseMirrorToString";
import { useLengthDebounce } from "src/lib/useLengthDebounce";
import { SuggestionListCarousel } from "./SuggestionListCarousel";
import { WrapupDropdownItem } from "./WrapupDropownItem";
import { GratidueThemeItem } from "./GratitudeThemeItem";
import { MindfulnessThemeItem } from "./MindfulnessThemeItem";

const SmartDropdown = (
    {
        journalPMJSON,
        selectedIndex,
        onSuggestionSelected,
        onWrapup,
        hide,
        initialSuggestion,
        saveSuggestions,
        theme
    } : {
        journalPMJSON: RemirrorJSON,
        selectedIndex: number
        onSuggestionSelected: (suggestion: Suggestion) => void,
        onWrapup: () => void,
        hide: boolean,
        initialSuggestion: Suggestion | undefined,
        saveSuggestions: (suggestions: SuggestionList[]) => void,
        theme: string

}) => {

    const debouncedJournalPMJSON = useDebounce(journalPMJSON, 2000);
    const lengthDebouncedJournalText = useLengthDebounce(journalPMJSON, 100);
    const [showDropdown, setShowDropdown] = useState(false);
    const [suggestionLists, setSuggestionLists] = useState<SuggestionList[]>([]);
    const [displaySuggestionLists, setDisplaySuggestionLists] = useState<SuggestionList[]>([]); // so that we don't update the list while it's open

    const handleSelect = (target: Suggestion | null) => {
        if (target !== null) {
            onSuggestionSelected(target)
        }
    };

    useEffect( () => {
        setShowDropdown(false);
        setDisplaySuggestionLists(suggestionLists);
    }, [journalPMJSON]);

    useEffect( () => {
        if (displaySuggestionLists.length > 0) {
            setShowDropdown(true);
        }
    }, [debouncedJournalPMJSON]);

    useEffect( () => {
        if (lengthDebouncedJournalText.length > 49) {
            getAllSuggestionLists(DROPDOWN_SUGGESTION_TYPES, initialSuggestion, lengthDebouncedJournalText).then((suggestionsLists) => {
                setSuggestionLists(suggestionsLists);
            });
        }
    }, [lengthDebouncedJournalText])

    useEffect( () => {
        if (displaySuggestionLists.length > 0 || theme !== "default") {
            setShowDropdown(true);
        }
    }, [displaySuggestionLists]);

    const getThemedItem = () => {
        switch (theme) {
            case "default": return undefined;
            case "gratitude": return <GratidueThemeItem onSelect={handleSelect} />
            case "mindfulness": return <MindfulnessThemeItem onSelect={handleSelect} />
        }
    }

    useEffect( () => {
        if (!showDropdown) {
            setDisplaySuggestionLists(suggestionLists);
        }
        if (suggestionLists.length > 0) {
            saveSuggestions(suggestionLists);
        }
    }, [suggestionLists])

    const list = {
        visible: { 
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: .7,
            }
        },
        hidden: { 
            opacity: 0,
            transition: {
                when: "afterChildren",
                duration: 5
            }
        },
    }

    const getDropdownItems = () => {
        let items: JSX.Element[] = [];

        if (displaySuggestionLists.length >= 2) {
            items = items.concat(displaySuggestionLists.map((suggestionList, index) => 
                <SuggestionListCarousel suggestionList={suggestionList} onSelect={handleSelect}/>
            ));
        }

        let themeItem = getThemedItem();
        if (themeItem) {
            items.push(themeItem);
        }

        items.push(<WrapupDropdownItem onSelect={onWrapup}/>);
        return items;
    }

    return <div className={hide ? "hidden" : ""}>
        
            <motion.ul className={`w-full list-none pl-0 ${showDropdown ? "pointer-events-auto" : "pointer-events-none"}`} initial="hidden" animate={showDropdown ? "visible" : "hidden"} variants={list}>
                {getDropdownItems().map(
                    (item, index) => {
                        return <DropdownItem onSelect={handleSelect} selected={selectedIndex ==  index}>
                           {item}
                        </DropdownItem>
                    })
                }

            </motion.ul>
        
    </div>
}

const DropdownItem = (
    {
        selected,
        onSelect,
        children,
    } : {
        selected: boolean,
        
        onSelect: (target: Suggestion | null) => void,
        children?: React.ReactNode,
    }
) => {

    const item = {
        visible: { 
            opacity: 1,
            transition: {
                ease: "easeOut",
                duration: 2,
            }
        },
        hidden: { opacity: 0,},
    }

    return (
        <motion.li variants={item}>
            <div className={`w-full rounded-xl flex flex-col p-4 my-2 border border-black opacity-50 cursor-pointer
                            ${selected ? "bg-neutral-200 opacity-100 " : ""}
                            hover:bg-neutral-200 hover:opacity-100`}>
                
                {children}

            </div>
        
        </motion.li>
    )
}


export { SmartDropdown}


import { ScrollingBackgroundGradient } from "../Gradient/ScrollingBackgroundGradient";
import { FeatureFeelings } from "./FeatureFeelings";
import { FeaturePrompt } from "./FeaturePrompt";
import { FeatureVisualization } from "./FeatureVisualization";
import LandingFAQ from "./LandingFAQ";
import { LandingHero } from "./LandingHero";


const LandingPage = () => {
    // const [gradientParams, setGradientParams] = useState<GradientParams>(defaultGradientParams);
    return (
        <div className="relative w-full overflow-hidden">
            <ScrollingBackgroundGradient />

            <div className="mx-4 sm:mx-0">
                
                <LandingHero />

                <FeaturePrompt />

                <FeatureFeelings />

                <FeatureVisualization />            

                <LandingFAQ />
            </div>

            
            
        </div>

    )
}
export default LandingPage;